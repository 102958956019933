import { ReactElement, ReactNode, useCallback, useEffect } from 'react';
import { useAtomValue, useAtom } from 'jotai';
import { ThemeProvider as ThemeProviderStyledComponents  } from 'styled-components';
import { themeNameAtom, themeSelectorAtom } from 'common/stores/theme.store.ts';
import { EThemes, EThemesSelector } from 'theme/enums/theme.enums.ts';
import { theme } from 'theme/theme.config.ts';

interface IProps {
  children: ReactNode;
}

const ThemeProvider = ({ children }: IProps): ReactElement => {
  const themeSelectorValue = useAtomValue(themeSelectorAtom);
  const [themeName, setThemeName] = useAtom(themeNameAtom);

  const changeThemeHandler = useCallback((e: MediaQueryListEvent): void => {
    setThemeName(e.matches ? EThemes.Dark : EThemes.Light);
  }, [setThemeName]);

  useEffect(() => {
    if (themeSelectorValue === EThemesSelector.Auto) {
      setThemeName(window.matchMedia('(prefers-color-scheme: dark)').matches ? EThemes.Dark : EThemes.Light);

      window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', changeThemeHandler);
    } else {
      setThemeName(themeSelectorValue as unknown as EThemes);
      window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', changeThemeHandler);
    }

    return () => {
      window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', changeThemeHandler);
    };
  }, [changeThemeHandler, setThemeName, themeSelectorValue]);

  return (
    <ThemeProviderStyledComponents theme={{
      ...theme[themeName],
      themeName,
    }}>
      {children}
    </ThemeProviderStyledComponents>
  );
};

export default ThemeProvider;
