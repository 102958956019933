import styled from 'styled-components';

export const SContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export const SContentContainer = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const SPreloaderContainer = styled.div`
  width: 146px;
  height: 110px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    path {
      fill: ${(props) => props.theme.themeV3.colors.green100};
    }
  }
`;

export const SPreloaderProgress = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  transition: 0.4s all;
  overflow: hidden;

  svg {
    path {
      fill: ${(props) => props.theme.themeV3.colors.green300};
    }
  }
`;

export const SLoadingText = styled.p` 
  margin-top: 32px;
`;

export const SBottomContainer = styled.div`
  margin-top: auto;
`;
