import { ReactElement, useEffect } from 'react';
import { useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { EScreens } from 'common/components/main-menu/enums/main.menu.enums.ts';
import { mainMenuBackBtnAtom, mainMenuScreenAtom } from 'common/stores/main-menu.store.ts';
import { ROUTES_MAPPING } from 'navigation/constants/route.constants.ts';
import DocumentIcon from 'assets/main-menu/document.icon.svg?react';
import MainMenuNavItem from '../components/main-menu-nav-item/main-menu-nav-item.compoinent.tsx';
import {
  SMainMenuCard,
  SMainMenuScreenContainer,
  SMainMenuScreenTitle,
} from '../main-menu.component.styles.ts';

const LegalAndPoliciesMainMenuScreen = (): ReactElement => {
  const navigate = useNavigate();
  const setMainMenuScreen = useSetAtom(mainMenuScreenAtom);
  const setMainMenuBack = useSetAtom(mainMenuBackBtnAtom);
  const { t } = useTranslation();

  useEffect(() => {
    setMainMenuBack(EScreens.Main);
  }, [setMainMenuBack]);

  return (
    <SMainMenuScreenContainer>
      <SMainMenuScreenTitle>
        {t('mainMenu.screen.legalAndPolicies.title')}
      </SMainMenuScreenTitle>

      <SMainMenuCard>
        <MainMenuNavItem
          icon={<DocumentIcon />}
          label={t('mainMenu.screen.legalAndPolicies.nav.termOfService')}
          navClickHandler={() => {
            setMainMenuScreen(null);
            navigate(ROUTES_MAPPING.PUBLIC.TERMS_AND_CONDITIONS);
          }}
        />
        <MainMenuNavItem
          icon={<DocumentIcon />}
          label={t('mainMenu.screen.legalAndPolicies.nav.privacyAndCookiesPolicy')}
          navClickHandler={() => {
            setMainMenuScreen(null);
            navigate(ROUTES_MAPPING.PUBLIC.PRIVACY_AND_COOKIES_POLICY);
          }}
        />
      </SMainMenuCard>
    </SMainMenuScreenContainer>
  );
};

export default LegalAndPoliciesMainMenuScreen;
