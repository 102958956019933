import styled from 'styled-components';
import NavArrowIcon from 'assets/main-menu/nav-arrow.icon.svg?react';

export const SMainMenuNavItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
  height: 32px;
  cursor: pointer;
`;

export const SMainMenuNavItemIcon= styled.div`
  display: flex;
  width: 32px;
  height: 32px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: ${props => props.theme.themeV3.colors.grey200};
  
  svg {
    path {
      stroke: ${props => props.theme.themeV3.colors.grey700};
    };
  }
`;

export const SMainMenuNavItemLabel = styled.p`
  flex: 1;
`;

export const SMainMenuNavItemSelectedValue = styled.p`
  color: ${props => props.theme.themeV3.colors.grey400} !important;
`;

export const SNavArrowIcon = styled(NavArrowIcon)`
  path {
    stroke: ${props => props.theme.themeV3.colors.grey400};
  }
`;
