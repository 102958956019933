import { apiClient } from 'common/clients/api.client.ts';
import { API_ENDPOINTS } from 'common/constants/api-endpoints.constants.ts';
import { EApiPathParams } from 'common/enums/api-path-params.enums.ts';
import {
  ITransactionsDataRes,
  ITransactionDataRes,
  ITransactionRefundRes,
  ITransactionsDataParams,
  ITransactionsCountRes,
  ITransactionInfoDataRes,
} from 'domains/transaction/interfaces/transaction.interfaces.ts';


export const getTransactionsDataService = async (
  pageParam: number,
  params: ITransactionsDataParams
): Promise<ITransactionsDataRes> => {
  const url = API_ENDPOINTS.TRANSACTIONS.TRANSACTIONS;
  const config = {
    params: {
      ...params,
      offset: pageParam,
    },
  };

  const res = await apiClient.get<ITransactionsDataRes>(url, config);

  return res.data;
};

export const countTransactionsService = async (params: ITransactionsDataParams): Promise<ITransactionsCountRes> => {
  const url = API_ENDPOINTS.TRANSACTIONS.TRANSACTIONS_COUNT;
  const config = {
    params,
  };

  const res = await apiClient.get<ITransactionsCountRes>(url, config);

  return res.data;
};

export const getTransactionDataService = async (sep24GUID: string): Promise<ITransactionDataRes> => {
  const url = API_ENDPOINTS.TRANSACTIONS.TRANSACTION.replace(EApiPathParams.Sep24GUID, sep24GUID);
  const res = await apiClient.get<ITransactionDataRes>(url);

  return res.data;
};

export const getTransactionInfoDataService = async (sep24GUID: string): Promise<ITransactionInfoDataRes> => {
  const url = API_ENDPOINTS.TRANSACTIONS.TRANSACTION_INFO.replace(EApiPathParams.Sep24GUID, sep24GUID);
  const res = await apiClient.get<ITransactionInfoDataRes>(url);

  return res.data;
};

export const postTransactionRefundService = async (
  sep24GUID: string,
): Promise<ITransactionRefundRes> => {
  const url = API_ENDPOINTS.TRANSACTIONS.REFUND.replace(EApiPathParams.Sep24GUID, sep24GUID);
  const res = await apiClient.put<ITransactionRefundRes>(url);

  return res.data;
};
