import { apiClient } from 'common/clients/api.client.ts';
import { API_ENDPOINTS } from 'common/constants/api-endpoints.constants.ts';
import {
  IOffRampReq,
  IOffRampRes,
  IOnRampReq,
  IOnRampRes,
  IOrderFlowDataReq,
  IOrderFlowDataRes,
} from 'domains/on-off-ramp/interfaces/on-off-ramp.interfaces.ts';

export const getOrderFlowDataService = async (reqData: IOrderFlowDataReq): Promise<IOrderFlowDataRes> => {
  const { signature } = reqData;
  const url = API_ENDPOINTS.ON_OFF_RAMP.ORDER_FLOW;
  const config = {
    params: {
      signature,
    },
  };
  const res = await apiClient.get<IOrderFlowDataRes>(url, config);

  return res.data;
};

export const postOnRampService = async (reqData: IOnRampReq): Promise<IOnRampRes> => {
  const url = API_ENDPOINTS.ON_OFF_RAMP.ON_RAMP;
  const res = await apiClient.post<IOnRampRes>(url, reqData);

  return res.data;
};

export const postOffRampService = async (reqData: IOffRampReq): Promise<IOffRampRes> => {
  const url = API_ENDPOINTS.ON_OFF_RAMP.OFF_RAMP;
  const res = await apiClient.post<IOffRampRes>(url, reqData);

  return res.data;
};
