import { atom } from 'jotai';

interface IHeaderConfigAtomData {
  backLink?: string | number | (() => void);
  title?: string;
  isMenuVisible?: boolean;
}

const headerConfigOriginAtom = atom<IHeaderConfigAtomData>({
  backLink: -1,
  isMenuVisible: true,
});

export const headerConfigAtom = atom(
  (get) => get(headerConfigOriginAtom),
  (get, set, data: IHeaderConfigAtomData) => {
    const originData = get(headerConfigOriginAtom);
    const mergedData = {
      ...originData,
      ...data,
    } as IHeaderConfigAtomData;

    set(headerConfigOriginAtom, mergedData);
  },
);
