import { JSX } from 'react';
import i18n from 'i18next';
import {
  EBlockchainNetwork,
  ETransactionStatus,
} from 'domains/transaction/enums/transaction.enums.ts';
import { EWallets } from 'domains/wallet/enums/wallet.enums.ts';
import EthereumNetworkIcon from 'assets/blockchain-network-icons/ethereum.icon.svg?react';
import StellarNetworkIcon from 'assets/blockchain-network-icons/stellar.icon.svg?react';
import TronNetworkIcon from 'assets/blockchain-network-icons/tron.icon.svg?react';
import CompletedIcon from 'assets/transaction-status-icons/completed.icon.svg?react';
import ErrorIcon from 'assets/transaction-status-icons/error.icon.svg?react';
import IncompleteIcon from 'assets/transaction-status-icons/incomplete.icon.svg?react';
import PendingIcon from 'assets/transaction-status-icons/pending.icon.svg?react';
import RefundedIcon from 'assets/transaction-status-icons/refunded.icon.svg?react';
import BeansAppWalletIcon from 'assets/wallet-logo-icons/beans-app.icon.svg?react';
import DefaultMerchantIcon from 'assets/wallet-logo-icons/default.icon.svg?react';
import LobstrWalletIcon from 'assets/wallet-logo-icons/lobstr.icon.svg?react';
import TrustWalletIcon from 'assets/wallet-logo-icons/trust-wallet.icon.svg?react';

const pendingStatusData = {
  label: i18n.t('transaction.statuses.pending'),
  icon: <PendingIcon />,
  className: 'pending',
};

export const TRANSACTION_STATUSES_MAPPING = {
  [ETransactionStatus.Incomplete]: {
    label: i18n.t('transaction.statuses.incomplete'),
    icon: <IncompleteIcon />,
    className: ETransactionStatus.Incomplete,
  },
  [ETransactionStatus.PendingUserTransferStart]: pendingStatusData,
  [ETransactionStatus.PendingUserTransferComplete]: pendingStatusData,
  [ETransactionStatus.PendingExternal]: pendingStatusData,
  [ETransactionStatus.PendingAnchor]: pendingStatusData,
  [ETransactionStatus.PendingStellar]: pendingStatusData,
  [ETransactionStatus.PendingTrust]: pendingStatusData,
  [ETransactionStatus.PendingUser]: {
    ...pendingStatusData,
    label: i18n.t('transaction.statuses.actionRequired'),
  },
  [ETransactionStatus.Completed]: {
    label: i18n.t('transaction.statuses.completed'),
    icon: <CompletedIcon />,
    className: ETransactionStatus.Completed,
  },
  [ETransactionStatus.Refunded]: {
    label: i18n.t('transaction.statuses.refunded'),
    icon: <RefundedIcon />,
    className: ETransactionStatus.Refunded,
  },
  [ETransactionStatus.Expired]: {
    label: i18n.t('transaction.statuses.expired'),
    icon: <IncompleteIcon />,
    className: ETransactionStatus.Expired,
  },
  [ETransactionStatus.Error]: {
    label: i18n.t('transaction.statuses.error'),
    icon: <ErrorIcon />,
    className: ETransactionStatus.Error,
  },
  default: {
    label: '',
    icon: <IncompleteIcon />,
    className: 'default-status',
  }
} as Record<ETransactionStatus | 'default', { label: string; icon: JSX.Element; className: string }>;

export const WALLETS_MAPPING = {
  [EWallets.Beans]: {
    icon: <LobstrWalletIcon />,
  },
  [EWallets.Lobstr]: {
    icon: <BeansAppWalletIcon />,
  },
  [EWallets.TrustWallet]: {
    icon: <TrustWalletIcon />,
  },
  [EWallets.Test]: {
    icon: <DefaultMerchantIcon />,
  },
};

export const BLOCKCHAIN_NETWORK_MAPPING = {
  [EBlockchainNetwork.Stellar]: {
    label: 'Stellar',
    icon: <StellarNetworkIcon />,
  },
  [EBlockchainNetwork.Tron]: {
    label: 'Tron',
    icon: <TronNetworkIcon />,
  },
  [EBlockchainNetwork.Ethereum]: {
    label: 'Ethereum',
    icon: <EthereumNetworkIcon />,
  },
};
