import { createQueryKeys } from '@lukemorales/query-key-factory';
import { EQueryKeys } from 'common/enums/query-keys.enums.ts';
import {
  IUserBillingFieldsRes,
} from 'domains/user-billing/interfaces/user-billing.interfaces.ts';
import {
  getUserBillingFieldsService,
} from 'domains/user-billing/services/user-billing.service.ts';

export const userBillingQueryKeys = createQueryKeys(EQueryKeys.UserBillingQueryKeys, {
  getUserBillingFields: () => ({
    queryKey: ['get-user-billing-fields'],
    queryFn: (): Promise<IUserBillingFieldsRes> => getUserBillingFieldsService(),
  }),
});
