import { createQueryKeys } from '@lukemorales/query-key-factory';
import { EQueryKeys } from 'common/enums/query-keys.enums.ts';
import {
  ITransactionDataRes,
  ITransactionsDataRes,
  ITransactionsDataParams,
  ITransactionInfoDataRes,
  ITransactionsCountRes,
} from 'domains/transaction/interfaces/transaction.interfaces.ts';
import {
  getTransactionsDataService,
  getTransactionDataService,
  getTransactionInfoDataService,
  countTransactionsService,
} from 'domains/transaction/services/transaction.service.ts';

export const transactionQueryKeys = createQueryKeys(EQueryKeys.TransactionKeys, {
  getTransactionsData: (params: ITransactionsDataParams) => ({
    queryKey: ['get-transactions-data', params],
    queryFn: ({ pageParam = 0 }: { pageParam: number }): Promise<ITransactionsDataRes> =>
      getTransactionsDataService(pageParam, params),
  }),
  getTransactionData: (sep24GUID: string) => ({
    queryKey: ['get-transaction-data', sep24GUID],
    queryFn: (): Promise<ITransactionDataRes> => getTransactionDataService(sep24GUID),
  }),
  getTransactionInfoData: (sep24GUID: string) => ({
    queryKey: ['get-transaction-info-data', sep24GUID],
    queryFn: (): Promise<ITransactionInfoDataRes> => getTransactionInfoDataService(sep24GUID),
  }),
  countTransactionsData: (params: ITransactionsDataParams) => ({
    queryKey: ['count-transactions-data', params],
    queryFn: (): Promise<ITransactionsCountRes> => countTransactionsService(params),
  }),
});
