import { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { Session } from '@ory/kratos-client';
import { useSetAtom, useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { queryKeysClient } from 'common/clients/query-key.client.ts';
import { queryClient } from 'common/clients/query.client.ts';
import { Loader } from 'common/components/loader/loader.component.tsx';
import { ENV_SMARTLOOK_TOKEN } from 'common/constants/env.constants.ts';
import { FPF_IFRAME_VALID_URLS } from 'common/constants/fpf.constants.ts';
import { EQueryKeys } from 'common/enums/query-keys.enums.ts';
import { EUrlQueryParams } from 'common/enums/url-query-params.enums.ts';
import { headerConfigAtom } from 'common/stores/header.store.ts';
import { themeNameAtom } from 'common/stores/theme.store.ts';
import { ROUTES_MAPPING } from 'navigation/constants/route.constants.ts';
import {
  SIframe
} from './fpf-payment.page.styles.ts';

const FpfPaymentPage = (): ReactElement => {
  const { i18n } = useTranslation();
  const iRef = useRef<HTMLIFrameElement | null>(null);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const themeName = useAtomValue(themeNameAtom);
  const [iframeUrl, setIframeUrl] = useState<string>('');
  const sessionData: Session | undefined = queryClient.getQueryData(
    queryKeysClient[EQueryKeys.AuthQueryKeys].getToSession().queryKey
  );
  const { id: userGuid = '' } = sessionData?.identity ?? { id: ''};
  const iframeUrlQueryParamValue = searchParams.get(EUrlQueryParams.IframeUrl) ?? '';
  const setHeaderConfig = useSetAtom(headerConfigAtom);
  const [isIframeLoading, setIsIframeLoading] = useState<boolean>(true);

  useEffect(() => {
    const searchParams = new URLSearchParams(iframeUrlQueryParamValue);
    searchParams.append(EUrlQueryParams.IsHeaderDisabled, 'true');
    searchParams.append(EUrlQueryParams.Slt, ENV_SMARTLOOK_TOKEN);
    searchParams.append(EUrlQueryParams.Slsesid, userGuid);
    searchParams.append(EUrlQueryParams.Theme, themeName);
    searchParams.append(EUrlQueryParams.Language, i18n.language);
    const iframeUrl = decodeURIComponent(searchParams.toString());

    setIframeUrl(iframeUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const postMessageDataHandler = useCallback((eventData: MessageEvent) => {
    if (typeof eventData.data === 'string') {
      const trustedRedirectKey = 'trusted_redirect:';

      if (eventData.data.startsWith(trustedRedirectKey)) {
        const redirectUrl = eventData.data.replace(trustedRedirectKey, '');
        setIframeUrl(redirectUrl);
      }
    }
  }, []);

  useEffect(() => {
    window.addEventListener('message', postMessageDataHandler);

    return () => {
      window.removeEventListener('message', postMessageDataHandler);
    };
  }, [postMessageDataHandler]);

  useEffect(() => {
    setHeaderConfig({
      title: '',
      backLink: () => {
        const postMessageData = {
          type: 'step_back',
        };
        iRef.current?.contentWindow?.postMessage(JSON.stringify(postMessageData), '*');
      },
    });

    return () => {
      setHeaderConfig({
        title: '',
        backLink: -1,
      });
    };
  }, [setHeaderConfig]);

  useEffect(() => {
    const isValidIframeUrl = FPF_IFRAME_VALID_URLS.some(substring => iframeUrlQueryParamValue.includes(substring));

    if (!iframeUrlQueryParamValue || !isValidIframeUrl) {
      navigate(ROUTES_MAPPING.NO_MATCH);
    }
  }, [iframeUrlQueryParamValue, navigate]);

  useEffect(() => {
    const postMessageData = {
      type: 'set_lang',
      message: i18n.language,
    };
    iRef.current?.contentWindow?.postMessage(JSON.stringify(postMessageData), '*');
  }, [i18n.language]);

  useEffect(() => {
    const postMessageData = {
      type: 'theme',
      message: themeName,
    };
    iRef.current?.contentWindow?.postMessage(JSON.stringify(postMessageData), '*');
  }, [themeName]);

  return (
    <>
      {(!iframeUrl || isIframeLoading) && <Loader fullscreen />}
      {iframeUrl && <SIframe
        ref={iRef}
        src={iframeUrl}
        onLoad={() => {
          setIsIframeLoading(false);
        }}
      />}
    </>
  );
};

export default FpfPaymentPage;
