import { ReactElement, useEffect, useState } from 'react';
import { useAtom, useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Loader } from 'common/components/loader/loader.component.tsx';
import { headerConfigAtom } from 'common/stores/header.store.ts';
import { themeNameAtom } from 'common/stores/theme.store.ts';
import { SIframe, SApsLogoWrapper } from 'pages/terms-and-privacy/terms-and-privacy.page.styles.ts';
import { theme } from 'theme/theme.config.ts';

const TermsAndPrivacyPage = ({ url, title }: { url: string; title: string }): ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const setHeaderConfig = useSetAtom(headerConfigAtom);
  const [themeName] = useAtom(themeNameAtom);
  const ApsLogo = theme[themeName].themeV3.apsLogo;
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setHeaderConfig({
      title: t(title),
    });

    return () => {
      setHeaderConfig({
        title: '',
      });
    };
  }, [navigate, setHeaderConfig, t, title]);

  return (
    <>
      {isLoading && <Loader fullscreen />}
      <SApsLogoWrapper>
        <ApsLogo />
      </SApsLogoWrapper>
      <SIframe
        title="title"
        src={url}
        onLoad={() => {
          setIsLoading(false);
        }}
      />
    </>
  );
};

export default TermsAndPrivacyPage;
