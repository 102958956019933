import React, { ReactElement } from 'react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Provider as JotaiStoreProvider } from 'jotai';
import { I18nextProvider } from 'react-i18next';
import { RouterProvider } from 'react-router';
import { mainStore } from 'common/clients/jotai-store.client.ts';
import { queryClient } from 'common/clients/query.client.ts';
import { initSentry } from 'common/clients/sentry.client.ts';
import { initSmartlook } from 'common/clients/smartlook.client.ts';
import { Loader } from 'common/components/loader/loader.component.tsx';
import { ENV_CI_RELEASE } from 'common/constants/env.constants.ts';
import ThemeProvider from 'common/providers/theme.provider.tsx';
import routerTree from 'navigation/routerTree.tsx';
import { GlobalStyle } from 'theme/global.styles.ts';
import i18n from 'translations/i18n.ts';

console.warn('Release version: ', ENV_CI_RELEASE);

// Initialize clients.
initSentry();
initSmartlook();

const App = (): ReactElement => (
  <React.StrictMode>
    <JotaiStoreProvider store={mainStore}>
    <I18nextProvider i18n={i18n}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider>
          <GlobalStyle />
          <RouterProvider
            fallbackElement={<Loader fullscreen />}
            router={routerTree}
          />
          <ReactQueryDevtools position="right" />
        </ThemeProvider>
      </QueryClientProvider>
    </I18nextProvider>
    </JotaiStoreProvider>
  </React.StrictMode>
);

export default App;
