import { ReactElement, useEffect, useState } from 'react';
import {
  UiNodeGroupEnum,
  UiNodeTypeEnum,
  UiNodeInputAttributesTypeEnum,
  UiNodeInputAttributes,
  SessionAuthenticationMethodMethodEnum,
} from '@ory/kratos-client';
import {
  FrontendApiUpdateLoginFlowRequest,
  FrontendApiUpdateRegistrationFlowRequest,
  UiNode,
} from '@ory/kratos-client/api.ts';
import { Form } from 'antd';
import { useSetAtom } from 'jotai';
import { useTranslation, Trans } from 'react-i18next';
import { Link, useLoaderData, useNavigate, useSearchParams } from 'react-router-dom';
import Button from 'common/components/button/button.component.tsx';
import ButtonSocial from 'common/components/button-social/button-social.component.tsx';
import FormItem from 'common/components/form-item/form-item.component.tsx';
import Input from 'common/components/input/input.component.tsx';
import { Loader } from 'common/components/loader/loader.component.tsx';
import Steps from 'common/components/steps/steps.component.tsx';
import WalletLogo from 'common/components/wallet-logo/wallet-logo.component.tsx';
import { IS_LOCAL } from 'common/constants/env.constants.ts';
import { ELocalStorageKeys } from 'common/enums/localStorage.enums.ts';
import { EUrlQueryParams } from 'common/enums/url-query-params.enums.ts';
import { headerConfigAtom } from 'common/stores/header.store.ts';
import { captureError, IError } from 'common/utils/error.utils.ts';
import { setHttps } from 'common/utils/url.utils.ts';
import {
  authCreateLoginFlowService,
  authGetLoginFlowService,
  authUpdateLoginFlowService,
  authUpdateRegistrationFlowService,
} from 'domains/auth/services/auth.service.ts';
import { TUiNodeAttributes } from 'domains/auth/types/auth.types.ts';
import { EOtpPageType } from 'pages/auth/otp/enums/otp.enums.ts';
import { ROUTES_MAPPING } from 'navigation/constants/route.constants.ts';
import LockIcon from 'assets/lock.icon.svg?react';
import GoogleLogoIcon from 'assets/logo-icons/google.icon.svg?react';
import { ILoginPageLoaderData, ISubmitCatchError } from './interfaces/login.interfaces.ts';
import {
  SFormWrapper,
  SDescription,
  STitle,
  SOidcBtnWrapper,
  SOrDivider,
  STermsConditions,
  SSecureStoredContainer,
  SButtonWrapper,
} from './login.page.styles.ts';

enum EFormItems {
  csrfToken = 'csrf_token',
  Email = 'email',
}

const LoginPage = (): ReactElement => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { registrationFlowData, initialValues } = useLoaderData() as ILoginPageLoaderData;
  const [isSignUpFormSubmitLoading, setIsSignUpFormSubmitLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const setHeaderConfig = useSetAtom(headerConfigAtom);
  const [searchParams] = useSearchParams();
  const returnToQueryParamValue = searchParams.get(EUrlQueryParams.ReturnTo) ?? '';

  useEffect(() => {
    const storedUserEmail = localStorage.getItem(ELocalStorageKeys.UserEmail);
    if (storedUserEmail) {
      form.setFieldsValue({ email: storedUserEmail });
    }
  }, [form]);

  useEffect(() => {
    setHeaderConfig({
      title: '',
      backLink: '',
    });

    return () => {
      setHeaderConfig({
        title: '',
        backLink: -1,
      });
    };
  }, [setHeaderConfig]);

  useEffect(() => {
    if (returnToQueryParamValue && !IS_LOCAL) {
      try {
        const returnUrl = new URL(returnToQueryParamValue);
        const returnUrlHost = returnUrl.host;
        const mainUrl = new URL(window.location.href);
        const mainUrlHost = mainUrl.host;

        if (returnUrlHost !== mainUrlHost) {
          mainUrl.host = returnUrlHost;
          window.location.replace(setHttps(mainUrl.href));
        }
      } catch (error) {
        captureError(error as IError);
      }
    }
  }, [returnToQueryParamValue]);

  const handleSignUpWithGoogle = async (): Promise<void> => {
    try {
      const oidcGroup = registrationFlowData.ui.nodes.filter((node) => node.group === UiNodeGroupEnum.Oidc);
      const defaultGroup = registrationFlowData.ui.nodes.filter((node) => node.group === UiNodeGroupEnum.Default);

      if (defaultGroup.length || oidcGroup.length) {
        const [defaultNode] = defaultGroup;
        const [oidcNode] = oidcGroup;

        const defaultAttributes = defaultNode.attributes as UiNodeInputAttributes;
        const oidcAttributes = oidcNode.attributes as UiNodeInputAttributes;

        const requestUpdateRegistrationFlowData = {
          flow: registrationFlowData.id,
          updateRegistrationFlowBody: {
            method: SessionAuthenticationMethodMethodEnum.Oidc,
            csrf_token: defaultAttributes.value as string,
            provider: oidcAttributes.value as string,
          }
        };

        await authUpdateRegistrationFlowService(requestUpdateRegistrationFlowData);
      }
    } catch (e: unknown) {
      const error = e as {
        response: {
          status: number;
          data: {
            redirect_browser_to?: string;
          };
        };
      };

      captureError(error as IError);

      if (error.response.status === 422) {
        const redirectUrl = error.response.data.redirect_browser_to;

        if (redirectUrl) {
          window.location.replace(setHttps(redirectUrl));
        }
      }
    }
  };

  const renderSocialLogin = (): null | ReactElement => {
    const {
      type = '',
      group= '',
      attributes = {},
    } = registrationFlowData.ui.nodes
      .filter((node) => node.group === UiNodeGroupEnum.Oidc)[0] as UiNode | undefined ?? {};

    if (
      type === UiNodeTypeEnum.Input
      && group === UiNodeGroupEnum.Oidc
      && (attributes as { value: string | undefined }).value === 'google'
    ) {
      return (
        <>
          <SOidcBtnWrapper>
            <ButtonSocial
              id="connect-continue-with-google-btn"
              onClick={handleSignUpWithGoogle}
              block
            >
              <GoogleLogoIcon />
              {t('login.btn.auth.google')}
            </ButtonSocial>
          </SOidcBtnWrapper>
          <SOrDivider>{t('login.divider.or')}</SOrDivider>
        </>
      );
    }

    return null;
  };

  const registrationFlow = async (values: { csrfToken: string, email: string }): Promise<void> => {
    const { csrfToken, email } = values;

    try {
      const requestRegistrationFlowData = {
        flow: registrationFlowData.id,
        updateRegistrationFlowBody: {
          csrf_token: csrfToken,
          traits: {
            email,
          },
          method: SessionAuthenticationMethodMethodEnum.Code
        },
      } as FrontendApiUpdateRegistrationFlowRequest;
      await authUpdateRegistrationFlowService(requestRegistrationFlowData);
    } catch (e) {
      const error = e as ISubmitCatchError;

      if (error.response.status !== 400) {
        captureError(error as IError);
      }

      const errorData = error.response.data;
      const errorMessageData = errorData?.ui?.messages?.[0];

      if (
        errorMessageData?.id === 1010014
        || errorMessageData?.id === 1040005
      ) {
        navigate(ROUTES_MAPPING.PUBLIC.OTP_CODE_VERIFICATION, {
          state: {
            type: EOtpPageType.Registration,
            verificationFlowId: errorData?.id,
            csrfToken,
            email,
            returnToQueryParamValue,
          },
        });
      } else {
        setIsSignUpFormSubmitLoading(false);
      }
    }
  };

  const formSubmitHandler = async (values: Record<EFormItems, string>): Promise<void> => {
    const { csrf_token: csrfToken, email } = values;

    try {
      if (email) {
        localStorage.setItem(ELocalStorageKeys.UserEmail, email);
      }

      const loginFlowData = await authCreateLoginFlowService({
        ...(returnToQueryParamValue ? { returnTo: returnToQueryParamValue } : null),
      });

      const formFieldsError = Object.keys(initialValues).map((fieldName) => ({
        name: fieldName,
        errors: [],
      }));
      form.setFields(formFieldsError);
      setIsSignUpFormSubmitLoading(true);

      const { return_to: returnTo } = await authGetLoginFlowService({ id: loginFlowData.id });

      const requestLoginFlowData = {
        flow: loginFlowData.id,
        updateLoginFlowBody: {
          csrf_token: csrfToken,
          identifier: email,
          method: SessionAuthenticationMethodMethodEnum.Code
        },
      } as FrontendApiUpdateLoginFlowRequest;
      await authUpdateLoginFlowService(requestLoginFlowData);

      if (returnTo) {
        window.location.replace(setHttps(returnTo));
      }
    } catch (e) {
      const error = e as ISubmitCatchError;

      if (error.response.status !== 400) {
        captureError(error as IError);
      }

      const errorData = error.response.data;
      const errorMessageData = errorData?.ui?.messages?.[0];

      if (errorMessageData?.id === 1010014) {
        navigate(ROUTES_MAPPING.PUBLIC.OTP_CODE_VERIFICATION, {
          state: {
            type: EOtpPageType.Login,
            verificationFlowId: errorData?.id,
            csrfToken,
            email,
            returnToQueryParamValue,
          },
        });
      } else if (errorMessageData?.id ===  4000035) {
        await registrationFlow({ csrfToken, email });
      } else {
        setIsSignUpFormSubmitLoading(false);
      }
    }
  };

  const renderHiddenFields = (
    node: UiNode,
    index: number
  ): null | ReactElement => {
    const attributes = node.attributes as TUiNodeAttributes;

    if (node.group === UiNodeGroupEnum.Oidc) {
      // skip oidc group.
      return null;
    }

    if (
      node.type === UiNodeTypeEnum.Input
      && node.group === UiNodeGroupEnum.Default
      && attributes.type === UiNodeInputAttributesTypeEnum.Hidden
    ) {
      return (
        <FormItem key={index} name={attributes.name} hidden>
          <Input type={attributes.type} />
        </FormItem>
      );
    }

    return null;
  };

  const renderEmailInput = (): null | ReactElement => {
    let attributesData: TUiNodeAttributes | object = {};

    registrationFlowData.ui.nodes.some((node: UiNode): boolean => {
      const attributes = node.attributes as TUiNodeAttributes;

      if (
        node.type === UiNodeTypeEnum.Input
        && attributes.type === UiNodeInputAttributesTypeEnum.Email
        && attributes.name === 'traits.email'
      ) {
        attributesData = node.attributes as TUiNodeAttributes;

        return true;
      }

      return false;
    });

    if (Object.keys(attributesData).length) {
      return (
        <FormItem
          label={t('login.input.email.label')}
          name="email"
          rules={[
            {
              required: (attributesData as TUiNodeAttributes).required,
              message: t('login.input.email.error.required'),
            },
            {
              type: 'email',
              message: t('login.input.email.error.correctEmail'),
            },
          ]}
        >
          <Input
            id="connect-email-fld"
            type={(attributesData as TUiNodeAttributes).type}
            placeholder={t('login.input.email.placeholder')}
          />
        </FormItem>
      );
    }

    return null;
  };

  const renderSubmitBtn = (): null | ReactElement => {
    let attributesData: TUiNodeAttributes | object = {};

    registrationFlowData.ui.nodes.some((node: UiNode): boolean => {
      const attributes = node.attributes as TUiNodeAttributes;

      if (
        node.type === UiNodeTypeEnum.Input
        && attributes.type === UiNodeInputAttributesTypeEnum.Submit
      ) {
        attributesData = node.attributes as TUiNodeAttributes;

        return true;
      }

      return false;
    });

    if (Object.keys(attributesData).length) {
      return (
        <Button
          id="connect-continue-btn"
          loading={isSignUpFormSubmitLoading}
          htmlType="submit"
          block
        >
          <Trans i18nKey="login.btn.submit" />
        </Button>
      );
    }

    return null;
  };

  return !Object.keys(registrationFlowData).length ? (
    <Loader fullscreen />
  ) : (
    <Form
      layout="vertical"
      form={form}
      initialValues={initialValues}
      onFinish={formSubmitHandler}
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
      }}
    >
      <WalletLogo />
      <Steps current={0} />
      <STitle>{t('login.title')}</STitle>
      <SDescription className="p2">{t('login.description')}</SDescription>
      {renderSocialLogin()}
      <SFormWrapper>
        {registrationFlowData.ui.nodes.map(renderHiddenFields)}
        {renderEmailInput()}
        <SSecureStoredContainer className="p3">
          <LockIcon />
          {t('login.securelyStored')}
        </SSecureStoredContainer>
      </SFormWrapper>
      <SButtonWrapper>
        <STermsConditions>
          <Trans
            i18nKey="login.checkbox.privacyPolicy.label"
            components={{
              termsConditionsLink: <Link
                id="connect-terms-lnk"
                to={ROUTES_MAPPING.PUBLIC.TERMS_AND_CONDITIONS}
              />,
              privacyPolicyLink: <Link
                id="connect-privacy-lnk"
                to={ROUTES_MAPPING.PUBLIC.PRIVACY_AND_COOKIES_POLICY}
              />,
            }}
          />
        </STermsConditions>
        {renderSubmitBtn()}
      </SButtonWrapper>
    </Form>
  );
};

export default LoginPage;
