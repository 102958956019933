import { ReactElement } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import Button from 'common/components/button/button.component.tsx';
import { EUrlQueryParams } from 'common/enums/url-query-params.enums.ts';
import { setHttps } from 'common/utils/url.utils.ts';
import { useGetFlowErrorQuery } from 'domains/auth/queries/auth.query.ts';
import { SButtonWrapper, SContent, SDescription, STitle, SWarningIcon } from 'pages/error/error.page.styles.ts';

const ErrorPage = (): ReactElement => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const returnToQueryParamValue = searchParams.get(EUrlQueryParams.ReturnTo) ?? '';
  // const codeQueryParamValue = searchParams.get(EUrlQueryParams.Code') ?? '';
  const messageQueryParamValue = searchParams.get(EUrlQueryParams.Message) ?? '';
  const idQueryParamValue = searchParams.get(EUrlQueryParams.Id) ?? '';

  const { data: kratosErrorData } = useGetFlowErrorQuery(
    idQueryParamValue,
    {
      enabled: !!idQueryParamValue,
    }
  );

  if (kratosErrorData) {
    console.error('Kratos Error Data: ', kratosErrorData);
  }

  const returnBtnClickHandler = (): void => {
    window.location.replace(setHttps(returnToQueryParamValue));
  };

  return (
    <>
      <SContent>
        <SWarningIcon />
        <STitle>{t('error.title')}</STitle>
        <SDescription className="p2">{messageQueryParamValue || t('error.description')}</SDescription>
      </SContent>
      <SButtonWrapper>
        <Button onClick={returnBtnClickHandler} block>
          <Trans i18nKey="error.btn.return" />
        </Button>
      </SButtonWrapper>
    </>
  );
};

export default ErrorPage;
