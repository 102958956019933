import { ReactNode, Suspense } from 'react';
import { Loader } from 'common/components/loader/loader.component.tsx';

interface IProps {
  children: ReactNode;
}

const LazyComponent  = (props: IProps): ReactNode => {
  const { children } = props;

  return (
    <Suspense fallback={<Loader fullscreen />}>
      {children}
    </Suspense>
  );
};

export default LazyComponent;
