import styled from 'styled-components';

export const SApsLogoWrapper = styled.div`
  margin: 16px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  
  svg {
    height: 32px;
    width: auto;
  }
`;

export const SIframe = styled.iframe`
  height: 100%;
  width: 100vw;
  padding: 0;
  margin: 0 -16px -32px -16px;
  border: 0;
  flex: 1;
`;
