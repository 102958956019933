import { ReactElement, useEffect, useState } from 'react';
import { useAtomValue } from 'jotai';
import { Outlet, Navigate, useSearchParams, useLocation } from 'react-router-dom';
import { EUrlQueryParams } from 'common/enums/url-query-params.enums.ts';
import { profileConfigAtom } from 'common/stores/profile-config.store.ts';
import { validateUrl } from 'common/utils/url.utils.ts';
import { ROUTES_MAPPING } from '../constants/route.constants.ts';

const PublicRouteGuard = (): ReactElement => {
  const location = useLocation();
  const [redirectPath, setRedirectPath] = useState<string>(ROUTES_MAPPING.PROTECTED.SUCCESSFUL_LOGIN);
  const [searchParams] = useSearchParams();
  const profileConfig = useAtomValue(profileConfigAtom);
  const isAuthenticatedUser = profileConfig.userIsAuthorized;

  useEffect(() => {
    const returnToFromQuery = searchParams.get(EUrlQueryParams.ReturnTo);

    if (returnToFromQuery && validateUrl(returnToFromQuery)) {
      const returnToFromQueryUrl = new URL(returnToFromQuery);
      const redirectPath = `${returnToFromQueryUrl.pathname}${returnToFromQueryUrl.search}`;

      setRedirectPath(redirectPath);
    }
  }, [searchParams, location]);

  if (isAuthenticatedUser && redirectPath) {
    return <Navigate to={redirectPath} />;
  }

  return <Outlet />;
};

export default PublicRouteGuard;
