import { ReactElement, useEffect, useState } from 'react';
import { message } from 'antd';
import { format, parseISO } from 'date-fns';
import { useSetAtom } from 'jotai';
import { Trans, useTranslation } from 'react-i18next';
import { useLoaderData, useNavigate, useSearchParams } from 'react-router-dom';
import Button from 'common/components/button/button.component.tsx';
import { EButtonStyleType } from 'common/components/button/button.conmponent.enums.ts';
import PoweredBy from 'common/components/powered-by/powered-by.component.tsx';
import { ESupportLinkType } from 'common/components/support-link/enums/support-link.component.enum.ts';
import SupportLink from 'common/components/support-link/support-link.component.tsx';
import Tooltip from 'common/components/tooltip/tooltip.component.tsx';
import TransactionStatusBadge from 'common/components/transaction-status-badge/transaction-status-badge.component.tsx';
import { EUrlQueryParams } from 'common/enums/url-query-params.enums.ts';
import { headerConfigAtom } from 'common/stores/header.store.ts';
import { captureError, IError } from 'common/utils/error.utils.ts';
import { textTrimmingUtil } from 'common/utils/text.utils.ts';
import { setHttps } from 'common/utils/url.utils.ts';
import { BLOCKCHAIN_NETWORK_MAPPING, WALLETS_MAPPING } from 'domains/transaction/constants/transaction.constants.tsx';
import {
  ETransactionKind,
  ETransactionState,
  ETransactionStatus,
} from 'domains/transaction/enums/transaction.enums.ts';
import { usePostTransactionRefundMutation } from 'domains/transaction/queries/transaction.query.ts';
import { ITransactionPageLoaderData } from 'pages/transaction/interfaces/transaction.interfaces.ts';
import { ROUTES_MAPPING } from 'navigation/constants/route.constants.ts';
import CopyIcon from 'assets/copy.icon.svg?react';
import InfoIcon from 'assets/info.icon.svg?react';
import SuccessCircleIcon from 'assets/success-circle.icon.svg?react';
import DefaultMerchantIcon from 'assets/wallet-logo-icons/default.icon.svg?react';
import {
  SAmount,
  SButtonContainer,
  SCard,
  SCardLine,
  SCardLineLabel,
  SGetAmount,
  SNoData,
  SInfoIconContainer,
  SSupportLinContainer,
  STopContainer,
  STopKind,
  STransactionIdValue,
  STransactionBlockchainIconWrapper,
  STransactionLogoContainer,
  STransactionLogo,
} from './transaction.page.styles.ts';

const TransactionPage = (): ReactElement => {
  const { t } = useTranslation();
  const [messageApi, contextHolder] = message.useMessage();
  const setHeaderConfig = useSetAtom(headerConfigAtom);
  const { transactionData } = useLoaderData() as ITransactionPageLoaderData;
  const [isRefundBtnLoading, setIsRefundBtnLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const idQueryParamValue = searchParams.get(EUrlQueryParams.Id) ?? '';
  const { mutateAsync: mutateAsyncPostTransactionRefundMutation } = usePostTransactionRefundMutation();

  useEffect(() => {
    setHeaderConfig({
      title: format(parseISO(transactionData.created_at), "MMM d · h:mm a"),
    });

    return () => {
      setHeaderConfig({
        title: '',
      });
    };
  }, [setHeaderConfig, transactionData.created_at]);

  const onClickKycButtonHandler = (): void => {
    navigate(`${ROUTES_MAPPING.PROTECTED.KYC}?${EUrlQueryParams.Id}=${idQueryParamValue}`);
  };

  const walletAddress =
    transactionData.kind === ETransactionKind.Deposit ? transactionData.details.to : transactionData.details.from;

  const isRefund =
    [ETransactionState.H2hRefunded, ETransactionState.FpfRefunded, ETransactionState.FpfCreatedForRefund].includes(
      transactionData.state,
    ) ||
    (transactionData.status === ETransactionStatus.PendingUser &&
      transactionData.state === ETransactionState.FpfExpired);

  const onRefundBtnHandler = async (): Promise<void> => {
    try {
      setIsRefundBtnLoading(true);

      const { action } = await mutateAsyncPostTransactionRefundMutation({ id: transactionData.id });

      if (action) {
        window.location.replace(setHttps(action));
      }

      setIsRefundBtnLoading(false);
    } catch (error) {
      setIsRefundBtnLoading(false);
      captureError(error as IError);
    }
  };

  const onCloseHandler = (): void => {
    const message = JSON.stringify({
      transaction: {
        ...transactionData,
      },
    });

    if (window.opener === undefined) {
      window.parent.postMessage(message, '*');
    } else {
      window.postMessage(message, '*');
    }
  };

  const amountIn = transactionData.details.amount_in || '0';
  const amountOut = transactionData.details.amount_out || '0';

  const amountInAsset = transactionData.details.amount_in_asset
    ? transactionData.details.amount_in_asset.split(':')[1]
    : '';

  const amountOutAsset = transactionData.details.amount_out_asset
    ? transactionData.details.amount_out_asset.split(':')[1]
    : '';

  const amountFeeAsset = transactionData.details.amount_fee_asset
    ? transactionData.details.amount_fee_asset.split(':')[1]
    : '';

  const isNoData = amountIn === '0' && amountOut === '0';
  const displayedAmountInAsset = amountInAsset || amountOutAsset;
  const displayedAmountOutAsset = amountOutAsset || amountInAsset;

  return (
    <>
      <STopContainer>
        <STransactionLogoContainer>
          <STransactionLogo>
            {transactionData.wallet.key in WALLETS_MAPPING ? (
              WALLETS_MAPPING[transactionData.wallet.key].icon
            ) : (
              <DefaultMerchantIcon />
            )}
          </STransactionLogo>
          {transactionData.blockchain in BLOCKCHAIN_NETWORK_MAPPING && (
            <STransactionBlockchainIconWrapper>
              {BLOCKCHAIN_NETWORK_MAPPING[transactionData.blockchain].icon}
            </STransactionBlockchainIconWrapper>
          )}
        </STransactionLogoContainer>
        <STopKind>{transactionData.kind}</STopKind>
        <SAmount>
          {isNoData ? (
            <SNoData>{t('transaction.info.noData')}</SNoData>
          ) : (
            `${amountIn} ${displayedAmountInAsset}`
          )}
        </SAmount>
        <SGetAmount>
          {isNoData ? t('transaction.info.description') : `${amountOut} ${displayedAmountOutAsset}`}
        </SGetAmount>
        <TransactionStatusBadge transactionData={transactionData} />
      </STopContainer>
      <SCard>
        <SCardLine>
          <SCardLineLabel>{t('transaction.info.youPay')}</SCardLineLabel>
          <div>
            {transactionData.details.amount_in} {amountInAsset}
          </div>
        </SCardLine>
        <SCardLine>
          <SCardLineLabel>{t('transaction.info.youGet')}</SCardLineLabel>
          <div>
            {transactionData.details.amount_out} {amountOutAsset}
          </div>
        </SCardLine>
        {/* <SCardLine> */}
        {/*   <SCardLineLabel> */}
        {/*     {t('transaction.info.networkFee')} */}
        {/*     <Tooltip */}
        {/*       title={t('transaction.info.networkFee.info')} */}
        {/*     > */}
        {/*       <div id="transaction-details-ntw-fee-info"> */}
        {/*         <InfoIcon /> */}
        {/*       </div> */}
        {/*     </Tooltip> */}
        {/*   </SCardLineLabel> */}
        {/*   <div>[TEST]</div> */}
        {/* </SCardLine> */}
        <SCardLine>
          <SCardLineLabel>
            {t('transaction.info.processingFee')}
            <Tooltip placement="topLeft" title={t('transaction.info.processingFe.ingo')}>
              <SInfoIconContainer id="transaction-details-proc-fee-info">
                <InfoIcon />
              </SInfoIconContainer>
            </Tooltip>
          </SCardLineLabel>
          <div>
            {transactionData.details.amount_fee} {amountFeeAsset}
          </div>
        </SCardLine>
        <hr />
        <SCardLine>
          <SCardLineLabel>{t('transaction.info.type')}</SCardLineLabel>
          <div style={{ textTransform: 'capitalize' }}>{transactionData.kind}</div>
        </SCardLine>
        <SCardLine>
          <SCardLineLabel>{t('transaction.info.time')}</SCardLineLabel>
          <div>{format(parseISO(transactionData.created_at), "MMM d, yyyy 'at' h.mm aaaa")}</div>
        </SCardLine>
        <SCardLine>
          <SCardLineLabel>{t('transaction.info.wallet')}</SCardLineLabel>
          <div>{transactionData.wallet.name}</div>
        </SCardLine>
        <SCardLine>
          <SCardLineLabel>{t('transaction.info.blockchain')}</SCardLineLabel>
          <div style={{ textTransform: 'capitalize' }}>{transactionData.blockchain}</div>
        </SCardLine>
      </SCard>

      <SCard>
        <SCardLine>
          <SCardLineLabel>{t('transaction.info.transactionId')}</SCardLineLabel>
          <STransactionIdValue
            id="transaction-details-copy-trx-id"
            role="none"
            onClick={async (): Promise<void> => {
              await navigator.clipboard.writeText(transactionData.id);
              await messageApi.open({
                type: 'success',
                content: t('transaction.info.transactionId.copied'),
                className: 'custom-success',
                icon: <SuccessCircleIcon />,
                style: {
                  marginTop: '80vh',
                },
              });
            }}
          >
            {contextHolder}
            {textTrimmingUtil(transactionData.id, 4, 4)}
            <CopyIcon />
          </STransactionIdValue>
        </SCardLine>
        <SCardLine>
          <SCardLineLabel>{t('transaction.info.walletAddress')}</SCardLineLabel>
          <STransactionIdValue
            id="transaction-details-copy-wallet-address"
            role="none"
            onClick={async (): Promise<void> => {
              await navigator.clipboard.writeText(walletAddress);
              await messageApi.open({
                type: 'success',
                content: t('transaction.info.walletAddress.copied'),
                className: 'custom-success',
                icon: <SuccessCircleIcon />,
                style: {
                  marginTop: '80vh',
                },
              });
            }}
          >
            {contextHolder}
            {textTrimmingUtil(walletAddress, 4, 4)}
            <CopyIcon />
          </STransactionIdValue>
        </SCardLine>
      </SCard>

      <SSupportLinContainer>
        <SupportLink
          id="transaction-details-support"
          type={ESupportLinkType.Transaction}
          data={{
            transactionId: transactionData.id,
            kind: transactionData.kind,
            amount: transactionData.amount,
            assetCode: transactionData.asset_code,
            createdAt: transactionData.created_at,
          }}
        />
      </SSupportLinContainer>

      <SButtonContainer>
        <Button id="transaction-details-wallet-btn" block onClick={onCloseHandler}>
          <Trans i18nKey="transaction.btn.close" />
        </Button>
        {isRefund && (
          <Button
            id="transaction-details-refund-btn"
            onClick={onRefundBtnHandler}
            styletype={EButtonStyleType.Secondary}
            block
            loading={isRefundBtnLoading}
          >
            <Trans i18nKey="transaction.btn.refund" />
          </Button>
        )}
        {transactionData.state === ETransactionState.PostKycStarted && (
          <Button block onClick={onClickKycButtonHandler} styletype={EButtonStyleType.Secondary}>
            <Trans i18nKey="transaction.btn.redirect.to.kyc" />
          </Button>
        )}
      </SButtonContainer>
      <PoweredBy />
    </>
  );
};

export default TransactionPage;
