import { ReactElement, useEffect, useState } from 'react';
import { Form } from 'antd';
import { endOfDay } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Sheet } from 'react-modal-sheet';
import Button from 'common/components/button/button.component.tsx';
import { EButtonStyleType } from 'common/components/button/button.conmponent.enums.ts';
import ChipsGroup from 'common/components/chips-group/chips-group.component.tsx';
import DatePicker from 'common/components/date-picker/date-picker.component.tsx';
import { ELocalStorageKeys } from 'common/enums/localStorage.enums.ts';
import { captureError, IError } from 'common/utils/error.utils.ts';
import {
  ETransactionsDataParams,
  ETransactionStatus,
} from 'domains/transaction/enums/transaction.enums.ts';
import {
  ITransactionsDataParams,
} from 'domains/transaction/interfaces/transaction.interfaces.ts';
import {
  useCountTransactionsDataQuery,
  useCountTransactionsMutation,
} from 'domains/transaction/queries/transaction.query.ts';
import { IUserWalletsDataRes } from 'domains/wallet/inrefaces/wallet.interfaces.ts';
import {
  FILTER_STATUS_MAPPING,
  FILTER_TYPE_MAPPING,
  FILTER_NETWORK_MAPPING,
} from 'pages/transactions/components/filter-bottom-sheet/filter-bottom-sheet.constants.ts';
import CloseIcon from 'assets/close.icon.svg?react';
import {
  SButtonContainer,
  SFormItem,
  SSheet,
  SSheetTitle,
  SFilterItem,
  SFilterItemLabel,
  SSheetHeader,
  SCloseIconWrapper,
  SButtonContainerInnerWrapper,
} from './filter-bottom-sheet.cmponent.styles.ts';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  walletsData: IUserWalletsDataRes | Record<string, never>;
  filtersDataHandler: (params: ITransactionsDataParams) => void;
}

interface IFields {
  [ETransactionsDataParams.Kind]: string;
  [ETransactionsDataParams.Status]: string;
  [ETransactionsDataParams.Blockchain]: string;
  [ETransactionsDataParams.WalletId]: string;
  [ETransactionsDataParams.StartCreatedAt]: number;
  [ETransactionsDataParams.EndCreatedAt]: number;
}

const FilterBottomSheetComponent = (props: IProps): ReactElement => {
  const {
    isOpen,
    onClose,
    filtersDataHandler,
    walletsData,
  } = props;
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [defaultFiltersParams, setDefaultFiltersParams] = useState<ITransactionsDataParams | null>(null);
  const { mutateAsync: mutateAsyncCountTransactionsMutation } = useCountTransactionsMutation();

  const {
    data: countTransactionsData,
    isLoading: isCountTransactionsDataLoading,
    isError: isCountTransactionsDataError,
    error: countTransactionsDataError,
  } = useCountTransactionsDataQuery(
    defaultFiltersParams ?? {},
    {
      enabled: !!defaultFiltersParams,
    });

  useEffect(() => {
    if (isCountTransactionsDataError) {
      captureError(countTransactionsDataError as IError);
    }
  }, [isCountTransactionsDataError, countTransactionsDataError]);

  useEffect(() => {
    if (isOpen) {
      const getFiltersData = localStorage.getItem(ELocalStorageKeys.TransactionFilters);

      if (getFiltersData === null) {
        const filteredStatus = FILTER_STATUS_MAPPING
        .filter((filter: { value: unknown }) => filter.value !== ETransactionStatus.Incomplete)
        .map((filter) => filter.value);

        const defaultFormFieldsValue = {
          [ETransactionsDataParams.Status]: filteredStatus,
        };
        form.setFieldsValue(defaultFormFieldsValue);

        const transactionParamsData = {
          [ETransactionsDataParams.Status]: defaultFormFieldsValue.status.toString(),
        };
        setDefaultFiltersParams(transactionParamsData as ITransactionsDataParams);
      } else {
        const filtersData = JSON.parse(getFiltersData) as Partial<Record<ETransactionsDataParams, string>>;
        const { start_created_at: startCreatedAt, end_created_at: endCreatedAt, ...restFields } = filtersData;

        const defaultFormFieldsValue = {
          ...restFields,
          ...(startCreatedAt
            ? { [ETransactionsDataParams.StartCreatedAt]: new Date(Number(startCreatedAt) * 1000) }
            : {}),
          ...(endCreatedAt ? { [ETransactionsDataParams.EndCreatedAt]: new Date(Number(endCreatedAt) * 1000) } : {}),
        };
        form.setFieldsValue(defaultFormFieldsValue);

        const transactionParamsData = {
          ...restFields,
          ...(startCreatedAt ? { [ETransactionsDataParams.StartCreatedAt]: startCreatedAt } : {}),
          ...(endCreatedAt ? { [ETransactionsDataParams.EndCreatedAt]: endCreatedAt } : {}),
        };
        setDefaultFiltersParams(transactionParamsData as ITransactionsDataParams);
      }
    }
  }, [form, isOpen, mutateAsyncCountTransactionsMutation]);

  const getFiltersData = (values: Partial<IFields>): ITransactionsDataParams => {
    const {
      kind,
      status,
      blockchain,
      wallet_id: walletId,
      start_created_at: startCreatedAt,
      end_created_at: endCreatedAt,
    } = values;  

    return {
      ...(kind ? { kind } : {}),
      ...(status ? { status } : {}),
      ...(walletId ? { wallet_id: walletId } : {}),
      ...(blockchain ? { blockchain } : {}),
      ...(startCreatedAt ? { start_created_at: Date.parse(startCreatedAt as unknown as string) / 1000 } : {}),
      ...(endCreatedAt ? { end_created_at: Date.parse(endCreatedAt as unknown as string) / 1000 } : {}),      
    };
  };

  const onFormChange = (values: IFields): void => {
    const filtersData = getFiltersData(values);
    const transactionParamsData = {
      ...filtersData,
      [ETransactionsDataParams.Status]: (filtersData.status ?? []).toString(),
    };

    setDefaultFiltersParams(transactionParamsData);
  };

  const resetBtnHandler = (): void => {
    localStorage.setItem(ELocalStorageKeys.TransactionFilters, JSON.stringify({}));
    form.resetFields();
    setDefaultFiltersParams({});
  };

  const formSubmitHandler = (values: IFields): void => { 
    localStorage.setItem(ELocalStorageKeys.TransactionFilters, JSON.stringify(getFiltersData(values)));
    const filtersData = getFiltersData(values);
    filtersDataHandler(filtersData);
    onClose();
  };
  
  return (
    <SSheet
      isOpen={isOpen}
      onClose={onClose}
      snapPoints={[0.95, 0]}
      initialSnap={0}
    >
      <Sheet.Container>
        <SSheetHeader>
          <SSheetTitle>{t('transactions.bottomSheet.filters.title')}</SSheetTitle>
          <SCloseIconWrapper onClick={onClose}>
            <CloseIcon />
          </SCloseIconWrapper>
        </SSheetHeader>
        <Sheet.Content>
          <Sheet.Scroller draggableAt="both">
            <Form
              layout="vertical"
              style={{ flex: 1 }}
              form={form}
              onFinish={formSubmitHandler}
              onValuesChange={(_, allFields: IFields) => {
                onFormChange(allFields);
              }}
            >
              <SFilterItem>
                <SFilterItemLabel>{t('transactions.bottomSheet.filters.status.label')}</SFilterItemLabel>
                <SFormItem
                  name={ETransactionsDataParams.Status}
                >
                  <ChipsGroup options={FILTER_STATUS_MAPPING} />
                </SFormItem>
              </SFilterItem>

              <SFilterItem>
                <SFilterItemLabel>{t('transactions.bottomSheet.filters.type.label')}</SFilterItemLabel>
                <SFormItem
                  name={ETransactionsDataParams.Kind}
                >
                  <ChipsGroup options={FILTER_TYPE_MAPPING} />
                </SFormItem>
              </SFilterItem>

              <SFilterItem>
                <SFilterItemLabel>{t('transactions.bottomSheet.filters.wallet.label')}</SFilterItemLabel>
                <SFormItem
                  name={ETransactionsDataParams.WalletId}
                >
                  <ChipsGroup
                    options={(walletsData.wallets ?? []).map(wallet => ({
                      label: wallet.name,
                      value: wallet.id,
                    }))}
                  />
                </SFormItem>
              </SFilterItem>

              <SFilterItem>
                <SFilterItemLabel>{t('transactions.bottomSheet.filters.network.label')}</SFilterItemLabel>
                <SFormItem
                  name={ETransactionsDataParams.Blockchain}
                >
                  <ChipsGroup options={FILTER_NETWORK_MAPPING} />
                </SFormItem>
              </SFilterItem>

              <SFilterItem>
                <SFilterItemLabel>{t('transactions.bottomSheet.filters.dateRange.label')}</SFilterItemLabel>
                <SFormItem
                  name={ETransactionsDataParams.StartCreatedAt}
                  label={t('transactions.bottomSheet.filters.dateRange.start.label')}
                >
                  <DatePicker
                    inputReadOnly
                    placeholder={t('transactions.bottomSheet.filters.dateRange.start.placeholder')}
                    showNow={false}
                    disabledDate={(current: Date) => current > endOfDay(new Date())}
                  />
                </SFormItem>
                <SFormItem
                  name={ETransactionsDataParams.EndCreatedAt}
                  label={t('transactions.bottomSheet.filters.dateRange.end.label')}
                >
                  <DatePicker
                    inputReadOnly
                    placeholder={t('transactions.bottomSheet.filters.dateRange.end.placeholder')}
                    showNow={false}
                    disabledDate={(current: Date) => current > endOfDay(new Date())}
                  />
                </SFormItem>
              </SFilterItem>

              <SButtonContainer>
                <SButtonContainerInnerWrapper>
                  <Button
                    onClick={resetBtnHandler}
                    styletype={EButtonStyleType.Secondary}
                    block
                  >
                    {t('transactions.bottomSheet.filters.btn.reset')}
                  </Button>
                  <Button
                    block
                    htmlType="submit"
                    loading={isCountTransactionsDataLoading}
                    disabled={countTransactionsData?.count === 0}
                  >
                    {t('transactions.bottomSheet.filters.btn.showResults', {
                      count: countTransactionsData?.count ?? 0
                    })}
                  </Button>
                </SButtonContainerInnerWrapper>
              </SButtonContainer>
            </Form>
          </Sheet.Scroller>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop
        onTap={() => {
          onClose();
        }}
      />
    </SSheet>
  );
};

export default FilterBottomSheetComponent;
