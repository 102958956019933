import { ReactElement, useEffect, useState, useCallback, useRef } from 'react';
import { Session } from '@ory/kratos-client';
import { useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { decodeToken } from 'react-jwt';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { queryKeysClient } from 'common/clients/query-key.client.ts';
import { queryClient } from 'common/clients/query.client.ts';
import { setSmartlookIdentity } from 'common/clients/smartlook.client.ts';
import PoweredBy from 'common/components/powered-by/powered-by.component.tsx';
import { EQueryKeys } from 'common/enums/query-keys.enums.ts';
import { EUrlQueryParams } from 'common/enums/url-query-params.enums.ts';
import { headerConfigAtom } from 'common/stores/header.store.ts';
import { captureError, IError } from 'common/utils/error.utils.ts';
import { setHttps } from 'common/utils/url.utils.ts';
import { EAction } from 'domains/auth/enums/auth.enums.ts';
import { lightWalkThroughInteractiveService } from 'domains/auth/services/auth.service.ts';
import { useOffRampMutation, useOnRampMutation } from 'domains/on-off-ramp/queries/on-off-ramp.query.ts';
import {
  useGetTransactionDataMutation,
} from 'domains/transaction/queries/transaction.query.ts';
import { IWalletDataRes } from 'domains/wallet/inrefaces/wallet.interfaces.ts';
import { IInteractiveFlowPageLocationState } from 'pages/interactive-flow/interfaces/interactive-flow.interfaces.ts';
import { ROUTES_MAPPING } from 'navigation/constants/route.constants.ts';
import ApsBigIcon from 'assets/logo-icons/aps-big-logo.icon.svg?react';
import {
  SBottomContainer,
  SContent,
  SPreloaderContainer,
  SPreloaderProgress,
  SLoadingText,
  SContentContainer,
} from './interactive-flow.page.styles.ts';

export const InteractiveFlowPage = (): ReactElement => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation() as IInteractiveFlowPageLocationState;
  const { state: locationState } = location;
  const effectRan = useRef(false);
  const setHeaderConfig = useSetAtom(headerConfigAtom);
  const interactiveIdQueryParamValue = searchParams.get(EUrlQueryParams.InteractiveId) ?? '';
  const amountQueryParamValue = searchParams.get(EUrlQueryParams.Amount) ?? '';
  const paymentMethodQueryParamValue = searchParams.get(EUrlQueryParams.PaymentMethod) ?? '';
  const signatureQueryParamValue = searchParams.get(EUrlQueryParams.Signature) ?? '';
  const getToSession = queryClient.getQueryData(queryKeysClient[EQueryKeys.AuthQueryKeys].getToSession().queryKey);
  const { identity } = getToSession as Session;
  const { mutateAsync: mutateAsyncGetTransactionDataMutation } = useGetTransactionDataMutation();
  const { mutateAsync: mutateAsyncOnRampMutation } = useOnRampMutation();
  const { mutateAsync: mutateAsyncOffRampMutation } = useOffRampMutation();
  const [progress, setProgress] = useState<number>(0);
  const [requestStep, setRequestStep] = useState<number>(0);
  const { t } = useTranslation();
  const walletDataFromCache: IWalletDataRes | undefined = queryClient.getQueryData(
    queryKeysClient[EQueryKeys.WalletKeys].getWalletData().queryKey
  );

  useEffect(() => {
    setHeaderConfig({
      backLink: '',
    });

    return () => {
      setHeaderConfig({
        title: '',
        backLink: '',
      });
    };
  }, [setHeaderConfig]);

  const loadingTextInfo = [
    'interactiveFlow.preLoader.resInfoText.start',
    'interactiveFlow.preLoader.resInfoText.next',
    'interactiveFlow.preLoader.resInfoText.billingFileds',
    'interactiveFlow.preLoader.resInfoText.kyc',
    'interactiveFlow.preLoader.resInfoText.fpf',
  ];

  const startInteractiveMainPath = `/sep0024/transactions/next/interactive/${interactiveIdQueryParamValue}`;
  const startInteractiveMainUrl = new URL(startInteractiveMainPath, window.location.origin);
  startInteractiveMainUrl.searchParams.set(EUrlQueryParams.Redirect, 'false');
  startInteractiveMainUrl.searchParams.set(EUrlQueryParams.Amount, amountQueryParamValue);
  startInteractiveMainUrl.searchParams.set(EUrlQueryParams.PaymentMethod, paymentMethodQueryParamValue);
  const startInteractiveFromUrl = decodeURIComponent(
    `${startInteractiveMainUrl.pathname}?${startInteractiveMainUrl.searchParams.toString()}`
  );

  const startInteractive = useCallback(
    async (url: string): Promise<void> => {
      const { url: nextUrl, action } = await lightWalkThroughInteractiveService(url);
      setProgress((prevProgress) => prevProgress + 20);
      setRequestStep((prevStep) => prevStep + 1);

      if (nextUrl) {
        if (action === EAction.Finish) {
          const transactionData = await mutateAsyncGetTransactionDataMutation({
            sep24GUID: interactiveIdQueryParamValue,
          });

          const userIdentityId = identity?.id;
          const identityTraits = identity?.traits as { email?: string } | undefined;
          const userEmail = identityTraits?.email ?? '';

          setSmartlookIdentity({
            interactiveID: interactiveIdQueryParamValue,
            userIdentityId,
            transactionData,
            userEmail,
            wallet_name: walletDataFromCache?.name,
          });

          setProgress(100);

          setTimeout(() => {
            window.location.replace(setHttps(nextUrl));
          }, 1000);
        } else {
          await startInteractive(`${nextUrl}?redirect=false`);
        }
      }
    },
    [
      identity,
      interactiveIdQueryParamValue,
      mutateAsyncGetTransactionDataMutation,
      walletDataFromCache
    ],
  );

  useEffect((): () => void => {
    if (!effectRan.current) {
      (async (): Promise<void> => {
        if (interactiveIdQueryParamValue) {
          await startInteractive(startInteractiveFromUrl);
        } else if (signatureQueryParamValue) {
          setProgress((prevProgress) => prevProgress + 20);

          const signatureData = decodeToken<{
            payload: {
              flow_type: 'off-ramp' | 'on-ramp';
            }
          }>(signatureQueryParamValue);
          const flowType = signatureData?.payload.flow_type;

          const reqData = {
            payment_method: paymentMethodQueryParamValue.split(':').slice(1, 3).join(':'),
            base_currency_amount: amountQueryParamValue,
            signature: signatureQueryParamValue,
          };

          let interactiveUrl: string;

          if (flowType === 'on-ramp') {
            ({ url: interactiveUrl } = await mutateAsyncOnRampMutation({ reqData }));
          } else {
            ({ url: interactiveUrl } = await mutateAsyncOffRampMutation({ reqData }));
          }

          if (interactiveUrl) {
            await startInteractive(`${interactiveUrl}?redirect=false`);
          }
        }
      })().catch(error => {
        captureError(error as IError);
        navigate(ROUTES_MAPPING.ERROR);
      });
    }

    return (): void => {
      effectRan.current = true;
    };
  }, [
    amountQueryParamValue,
    interactiveIdQueryParamValue,
    locationState,
    mutateAsyncOffRampMutation,
    mutateAsyncOnRampMutation,
    navigate,
    paymentMethodQueryParamValue,
    signatureQueryParamValue,
    startInteractive,
    startInteractiveFromUrl
  ]);

  return (
    <SContent>
      <SContentContainer>
        <SPreloaderContainer>
          <ApsBigIcon />
          <SPreloaderProgress style={{ width: `${progress}%` }}>
          <ApsBigIcon />
          </SPreloaderProgress>
        </SPreloaderContainer>
        <SLoadingText className="p2">{t(loadingTextInfo[requestStep])}</SLoadingText>
      </SContentContainer>
      <SBottomContainer>
        <PoweredBy />
      </SBottomContainer>
    </SContent>
  );
};
