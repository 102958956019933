import { Steps } from 'antd';
import styled from 'styled-components';

export const SSteps = styled(Steps)`
  &.ant-steps-horizontal {
    .ant-steps-item {
      padding-inline-start: 2px !important;

      &:last-child {
        flex: 1;
      }
      
      &.ant-steps-item-active,
      &.ant-steps-item-finish {
        .ant-steps-item-container {
          .ant-steps-item-content {
            .ant-steps-item-title {
              &:after {
                background: ${props => props.theme.themeV3.colors.green300};
              }
            }
          }
        }
      }
      
      .ant-steps-item-container {
        .ant-steps-item-icon {
          display: none;
        }
        
        .ant-steps-item-content {
          width: 100%;
          
          .ant-steps-item-title {
            display: flex;
            flex-direction: column;
            text-align: center;
            padding-inline-end: 2px;
            color: ${props => props.theme.themeV3.colors.grey700};
            font-variant-numeric: lining-nums tabular-nums;
            font-family: "Plus Jakarta Sans", sans-serif;
            font-size: 12px;
            font-weight: 600;
            line-height: 20px;

            &:after {
              content: '';
              margin-top: 4px;
              display: block;
              position: static ;
              height: 4px;
              border-radius: 32px;
              background: ${props => props.theme.themeV3.colors.green100};
              width: 100%;
            }
          }
        }
      }
    }
  }
  
  
  
  
  
      // font-family: "Plus Jakarta Sans", sans-serif;
  // padding: 16px;
  // font-size: 16px;
  // font-weight: 500;
  // line-height: 24px;
  // color: ${props => props.theme.themeV3.colors.grey700};
  // background: ${props => props.theme.themeV3.colors.grey200} !important;
  // border-radius: 8px;
  // border: 0;
  //
  // input::placeholder {
  //   color: ${props => props.theme.themeV3.colors.grey400};
  // }
  //
  // &:hover,
  // &:focus,
  // &:focus-within {
  //   border: 0;
  //   color: ${props => props.theme.themeV3.colors.grey700};
  //   background: ${props => props.theme.themeV3.colors.grey200};
  // }
  //
  // &.ant-input-disabled {
  //   color: ${props => props.theme.themeV3.colors.grey300};
  //   background: ${props => props.theme.themeV3.colors.grey200};
  //
  //   input::placeholder {
  //     color: ${props => props.theme.themeV3.colors.grey300};
  //   }
  // }
  //
  // &.ant-input-status-error {
  //   background: ${props => props.theme.themeV3.colors.red100} !important;
  //   border-color: ${props => props.theme.themeV3.colors.red300} !important;
  //   margin-bottom: 4px;
  //
  //   input::placeholder {
  //     color: ${props => props.theme.themeV3.colors.red200} !important;
  //   }
  // }
`;
