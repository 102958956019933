import { Input } from 'antd';
import { MaskedInput } from 'antd-mask-input';
import styled from 'styled-components';

export const SInput = styled(Input)`
  font-family: "Plus Jakarta Sans", sans-serif;
  padding: 16px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: ${props => props.theme.themeV3.colors.grey700};
  background: ${props => props.theme.themeV3.colors.grey200} !important;
  border-radius: 8px;
  border: 0;

  input::placeholder {
    color: ${props => props.theme.themeV3.colors.grey400};
  }
  
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  input[type=number] {
    -moz-appearance: textfield;
  }

  &:hover,
  &:focus,
  &:focus-within {
    border: 0;
    color: ${props => props.theme.themeV3.colors.grey700};
    background: ${props => props.theme.themeV3.colors.grey200};
  }
  
  &.ant-input-disabled {
    color: ${props => props.theme.themeV3.colors.grey300};
    background: ${props => props.theme.themeV3.colors.grey200};

    input::placeholder {
      color: ${props => props.theme.themeV3.colors.grey300};
    }
  }
  
  &.ant-input-status-error {
    background: ${props => props.theme.themeV3.colors.red100} !important;
    border-color: ${props => props.theme.themeV3.colors.red300} !important;
    margin-bottom: 4px;

    input::placeholder {
      color: ${props => props.theme.themeV3.colors.red200} !important;
    }
  }

  .ant-input-clear-icon {
    width: auto;
    height: 12px;
    line-height: 0;

    svg {
      height: 12px;
      width: auto;

      path {
        stroke: ${props => props.theme.themeV3.colors.grey700};
      }
    }
  }
`;

export const SMaskedInput = styled(MaskedInput)`
  padding: 16px;
  background: ${props => props.theme.themeV3.colors.grey200} !important;
  border-radius: 8px;
  border: 0;
  height: 56px;
  
  input {
    font-family: "Plus Jakarta Sans", sans-serif !important;
    font-size: 16px !important;
    font-weight: 500;
    line-height: 24px;
    color: ${props => props.theme.themeV3.colors.grey700} !important;
  }
  
  input::placeholder {
    color: ${props => props.theme.themeV3.colors.grey400} !important;
  }
  
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  input[type=number] {
    -moz-appearance: textfield;
  }

  &:hover,
  &:focus,
  &:focus-within {
    color: ${props => props.theme.themeV3.colors.grey700} !important;
    background: ${props => props.theme.themeV3.colors.grey200} !important;
  }
  
  .ant-input-clear-icon {
    width: auto;
    height: 12px;
    line-height: 0;
    
    svg {
      height: 12px;
      width: auto;
      
      path {
        stroke: ${props => props.theme.themeV3.colors.grey700};
      }
    }
  }
`;
