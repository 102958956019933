import { JSX } from 'react';
import { TRANSACTION_STATUSES_MAPPING } from 'domains/transaction/constants/transaction.constants.tsx';
import { ITransactionDataRes, ITransaction } from 'domains/transaction/interfaces/transaction.interfaces.ts';
import {
  ETransactionStatusBadgeType
} from './enums/transaction-status-badge.enums.ts';
import { SStatusContainer } from './transaction-status-badge.styles.ts';

interface ITransactionProps {
  type?: ETransactionStatusBadgeType.Simple | undefined,
  transactionData: ITransactionDataRes | ITransaction;
}

const TransactionStatusBadge = (props: ITransactionProps): JSX.Element => {
  const { transactionData, type } = props;

  let transactionStatusMapping = {
    ...TRANSACTION_STATUSES_MAPPING.default,
    label: `${(transactionData.status as string).replaceAll('_', ' ')}!`,
  };

  if (transactionData.status in TRANSACTION_STATUSES_MAPPING) {
    transactionStatusMapping = TRANSACTION_STATUSES_MAPPING[transactionData.status];
  }

  const classNames = [
    transactionStatusMapping.className,
    type,
  ].filter(item => !!item).join(' ');

  return (
    <SStatusContainer className={classNames}>
      {transactionStatusMapping.icon}
      {transactionStatusMapping.label}
    </SStatusContainer>
  );
};

TransactionStatusBadge.defaultProps = {
  type: undefined,
};

export default TransactionStatusBadge;
