import i18n from 'i18next';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import HttpBackend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import translateEN from './en/translation.json';
import translatePT from './pt/translation.json';
import { APP_CONFIG, ELanguages } from '../app.config.ts';

const resources = {
  [ELanguages.En]: {
    translation: translateEN,
  },
  [ELanguages.Pt]: {
    translation: translatePT,
  },
};

i18n
  .use(initReactI18next)
  .use(HttpBackend)
  .use(I18nextBrowserLanguageDetector)
  .init({
    detection: {
      order: ['querystring', 'localStorage', 'htmlTag', 'path', 'subdomain'],
    },
    resources,
    fallbackLng: APP_CONFIG.FALLBACK_LOCALE,
    interpolation: {
      escapeValue: false,
    },
  })
  .then(data => data)
  .catch(error => {
    console.error(error);
  });

export default i18n;
