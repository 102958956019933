import { ReactElement } from 'react';
import { SButton } from 'common/components/button/button.component.styles.ts';
import { IButtonProps } from 'common/components/button/button.conmponent.interfaces.ts';

const Button = (props: IButtonProps): ReactElement => {
  const { children, loading } = props;

  return (
    <SButton type="primary" {...props}>
      {!loading && children}
    </SButton>
  );
};

Button.defaultProps = {
  styletype: undefined,
};

export default Button;
