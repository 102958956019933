import { ReactElement } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import Button from 'common/components/button/button.component.tsx';
import { EUrlQueryParams } from 'common/enums/url-query-params.enums.ts';
import { setHttps } from 'common/utils/url.utils.ts';
import { SButtonWrapper, SContent, SDescription, STitle, SWarningIcon } from 'pages/no-match/no-match.page.styles.ts';

export const NoMatchPage = (): ReactElement => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const returnToQueryParamValue = searchParams.get(EUrlQueryParams.ReturnTo) ?? '';

  const returnBtnClickHandler = (): void => {
    window.location.replace(setHttps(returnToQueryParamValue));
  };

  return (
    <>
      <SContent>
        <SWarningIcon />
        <STitle>{t('noMatch.title')}</STitle>
        <SDescription className="p2">
          {t('noMatch.description')}<br />
          {t('noMatch.description2')}
        </SDescription>
      </SContent>
      <SButtonWrapper>
        <Button onClick={returnBtnClickHandler} block>
          <Trans i18nKey="noMatch.btn.return" />
        </Button>
      </SButtonWrapper>
    </>
  );
};
