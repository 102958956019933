import { Tooltip } from 'antd';
import styled from 'styled-components';
import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  .custom-tooltip {
    .ant-tooltip-arrow {
      &:before{
        background: ${props => props.theme.themeV3.colors.green200};
      }
    }
    
    .ant-tooltip-inner {
      padding: 8px;
      background: ${props => props.theme.themeV3.colors.green200};
      color: ${props => props.theme.themeV3.colors.green500};
      font-variant-numeric: lining-nums tabular-nums;
      font-family: "Plus Jakarta Sans", sans-serif;
      font-size: 12px;
      font-weight: 600;
      line-height: 20px;
      border-radius: 4px;
    }
  }
`;

export const STooltip = styled(Tooltip)`
  
`;
