import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  .ant-input-outlined:focus-within {
    box-shadow: none !important;
  }

  .ant-select-focused .ant-select-selector,
  .ant-select-selector:focus,
  .ant-select-selector:active,
  .ant-select-open .ant-select-selector {
    box-shadow: none !important;
  }
  
  * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }

  html {
    height: 100%;
  }
  
  body {
    margin: 0;
    padding: 0;
    background: ${props => props.theme.themeV3.colors.grey100};
    font-family: "Plus Jakarta Sans", sans-serif;
    height: 100%;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }

  #root {
    min-height: 100vh;
    height: auto;
    display: flex;
    flex-direction: column;
  }
  
  a {
    color: ${props => props.theme.themeV3.colors.green400};
    text-decoration: none;
    
    &:hover {
      color: ${props => props.theme.themeV3.colors.green300};
      text-decoration: none;
    }
  }
  
  h1 {
    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    color: ${props => props.theme.themeV3.colors.grey700};
    font-variant-numeric: lining-nums proportional-nums;
  }

  h2 {
    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    font-variant-numeric: lining-nums tabular-nums;
    color: ${props => props.theme.themeV3.colors.grey700};
  }

  h3 {
    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    font-variant-numeric: lining-nums tabular-nums;
    color: ${props => props.theme.themeV3.colors.grey700};
  }

  h4 {
    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    font-variant-numeric: lining-nums tabular-nums;
    color: ${props => props.theme.themeV3.colors.grey700};
  }
  
  p.p1 {
    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    font-variant-numeric: lining-nums proportional-nums;
    color: ${props => props.theme.themeV3.colors.grey700};
  }

  p.p2 {
    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    font-variant-numeric: lining-nums proportional-nums;
    color: ${props => props.theme.themeV3.colors.grey700};
  }

  p.p3 {
    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    font-variant-numeric: lining-nums tabular-nums;
    color: ${props => props.theme.themeV3.colors.grey700};
  }

  p.p4 {
    font-family: "Plus Jakarta Sans", sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    font-variant-numeric: lining-nums tabular-nums;
    color: ${props => props.theme.themeV3.colors.grey700};
  }

  .ant-message {
    .ant-message-notice-wrapper {
      .ant-message-notice {
        .ant-message-notice-content {
          padding: 8px 16px;
          border-radius: 10px;
          background: ${props => props.theme.themeV3.colors.grey200};

          .ant-message-custom-content {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;
            color: ${props => props.theme.themeV3.colors.grey700};
            font-variant-numeric: lining-nums tabular-nums;
            font-family: "Plus Jakarta Sans", sans-serif;
            font-size: 14px;
            font-weight: 500;
            line-height: 22px;
            
            &.ant-message-loading {
              .anticon {
                color: #5FCF80;
              }
            }
          }
        }
      }
    }
  }
  
  .ant-picker-dropdown {
    z-index: 9999999;
    color: ${props => props.theme.themeV3.colors.grey700} !important;
    font-variant-numeric: lining-nums tabular-nums !important;
    font-family: "Plus Jakarta Sans", sans-serif !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    
    .ant-picker-cell {
      .ant-picker-cell-inner {
        border-radius: 16px;
      }
      
      &.ant-picker-cell-selected {
        .ant-picker-cell-inner {
          background: ${props => props.theme.themeV3.colors.green400} !important;
        }
      }
      
      &.ant-picker-cell-today {
        .ant-picker-cell-inner {
          &:before {
            border-color: ${props => props.theme.themeV3.colors.green400} !important;
          }
        }
      }
    }
  }
`;
