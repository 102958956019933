import styled from 'styled-components';
import SuccessIcon from 'assets/success.icon.svg?react';

export const SContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

export const SSuccessIcon = styled(SuccessIcon)`
`;

export const STitle = styled.h2`
  margin-bottom: 16px;
`;
