export const setHttps = (url: string): string => {
  try {
    const newURL = new URL(url);
    newURL.protocol = 'https://';

    return newURL.href;
  } catch (error){
    console.error(error);

    return url;
  }
};

export const validateUrl = (url: string): boolean => {
  try {
    const testUrl = new URL(url);
    return !!testUrl;
  } catch {
    return false;
  }
};
