import { apiClient } from 'common/clients/api.client.ts';
import { API_ENDPOINTS } from 'common/constants/api-endpoints.constants.ts';
import { IUserWalletsDataRes, IWalletDataRes } from 'domains/wallet/inrefaces/wallet.interfaces.ts';

export const getUserWalletsDataService = async (): Promise<IUserWalletsDataRes> => {
  const url = API_ENDPOINTS.WALLET.WALLETS;
  const res = await apiClient.get<IUserWalletsDataRes>(url);

  return res.data;
};

export const getWalletDataService = async (): Promise<IWalletDataRes> => {
  const url = API_ENDPOINTS.WALLET.WALLET;
  const res = await apiClient.get<IWalletDataRes>(url);

  return res.data;
};
