import { notification } from 'antd';
import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import qs from 'qs';
import { API_ENDPOINTS } from 'common/constants/api-endpoints.constants.ts';
import { ROUTES_MAPPING } from 'navigation/constants/route.constants.ts';
import routerTree from 'navigation/routerTree.tsx';
import i18n from '../../translations/i18n.ts';

export const apiClient = axios.create({
  baseURL: API_ENDPOINTS.BASE_URL,
  withCredentials: true,
  paramsSerializer: {
    serialize: (params) => qs.stringify(params, { indices: false }),
  },
});

const apiClientInterceptorsReq = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => ({
  ...config,
});

const apiClientInterceptorsReqError = async (error: AxiosError): Promise<AxiosError> => Promise.reject(error);

const apiClientInterceptorsRes = (response: AxiosResponse): AxiosResponse => response;

const apiClientInterceptorsResError = async (error: AxiosError): Promise<AxiosError> => {
  const { response } = error;
  const searchParams = new URLSearchParams();

  if (response?.status === 400) {
    // Disable Notification popup for billing fields path to correct handle error fields data
    if (response.config.url === API_ENDPOINTS.USER.BILLING_FIELDS) {
      return Promise.reject(error);
    }
  }

  if (response?.status === 401) {
    searchParams.append('from', window.location.pathname);

    notification.warning({
      message: i18n.t('apiClient.error.401.message'),
      duration: 10,
    });

    await routerTree.navigate(`${ROUTES_MAPPING.PUBLIC.LOGIN}?${searchParams.toString()}`);

    return Promise.reject(error);
  }

  if (response?.status === 403) {
    notification.error({
      message: i18n.t('apiClient.error.403.message'),
      description: i18n.t('apiClient.error.403.description'),
      duration: 10,
    });

    return Promise.reject(error);
  }

  if (response?.status === 500) {
    // Disable Notification popup for profile endpoint
    if (response.config.url === API_ENDPOINTS.USER.PROFILE) {
      return Promise.reject(error);
    }

    notification.error({
      message: i18n.t('apiClient.error.500.message'),
      description: i18n.t('apiClient.error.500.description'),
      duration: 10,
    });

    return Promise.reject(error);
  }

  notification.error({
    message: i18n.t('apiClient.error.main.message'),
    description: error.message,
    duration: 10,
  });

  return Promise.reject(error);
};

apiClient.interceptors.request.use(apiClientInterceptorsReq, apiClientInterceptorsReqError);
apiClient.interceptors.response.use(apiClientInterceptorsRes, apiClientInterceptorsResError);
