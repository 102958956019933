import styled from 'styled-components';

export const STransactionsContainer = styled.div``;

export const SContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  gap: 36px;
  margin-top: 36px;
  flex: 1;
`;

export const STopBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const STitle = styled.h2`
`;

export const SFilterIconWrapper = styled.div`
  cursor: pointer;
  color: ${(props) => props.theme.themeV3.colors.grey700};
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.themeV3.colors.green300};
  background: ${(props) => props.theme.themeV3.colors.grey100};
  padding: 8px 16px;
  gap: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  svg path {
    stroke: ${(props) => props.theme.themeV3.colors.grey700};
  }
`;

export const SGroupDate = styled.div`
  margin-top: 16px;
  color: ${(props) => props.theme.themeV3.colors.grey700};
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
`;

export const STransaction = styled.div`
  padding: 16px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  cursor: pointer;
  transition: all 0.4s;
`;

export const STransactionLogoContainer = styled.div`
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const STransactionLogo = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
`;

export const STransactionBlockchainIconWrapper = styled.div`
  position: absolute;
  width: 24px;
  height: 24px;
  bottom: -4px;
  right: -4px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.theme.themeV3.colors.green200};
  border: 1px solid ${props => props.theme.themeV3.colors.grey100};

  svg {
    width: 14px;
    height: auto;
  }
`;

export const STransactionMiddleColumn = styled.div``;

export const STransactionLastColumn = styled.div`
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const STransactionKind = styled.div`
  color: ${(props) => props.theme.themeV3.colors.grey700};
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-transform: capitalize;
`;

export const STransactionDate = styled.div`
  color: ${(props) => props.theme.themeV3.colors.grey400};
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  margin-top: 4px;
`;

export const STransactionAmount = styled.div`
  color: ${(props) => props.theme.themeV3.colors.grey700};
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 4px;
`;

export const SNoDataInfo = styled.span`
  color: ${(props) => props.theme.themeV3.colors.grey300};
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`;

export const SInfiniteScrollLoader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  
  svg {
    path {
      fill: #00CC70;
    }
  }
`;

export const SBottomContainer = styled.div`
  margin-top: auto;
`;

export const SNoTrxDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1
`;

export const SNoTrxDataTitle = styled.h2`
  margin-top: 16px;
  text-align: center;
`;

export const SNoTrxDataDescription = styled.p`
  margin-top: 16px;
  text-align: center;
`;
