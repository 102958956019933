import { createQueryKeys } from '@lukemorales/query-key-factory';
import { EQueryKeys } from 'common/enums/query-keys.enums.ts';
import { IOrderFlowDataReq, IOrderFlowDataRes } from 'domains/on-off-ramp/interfaces/on-off-ramp.interfaces.ts';
import { getOrderFlowDataService } from 'domains/on-off-ramp/services/on-off-ramp.service.ts';

export const onOffRampQueryKeys = createQueryKeys(EQueryKeys.OnOffRamp, {
  getOrderFlowData: (reqData: IOrderFlowDataReq) => ({
    queryKey: ['get-order-flow-data'],
    queryFn: (): Promise<IOrderFlowDataRes> => getOrderFlowDataService(reqData),
  }),
});
