import { ReactElement } from 'react';
import { useAtomValue, useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  SContainer,
  SLink,
  SLinkContainer,
  SMenuContainer, SMenuIcon,
  STitle,
} from 'common/components/header/header.component.styles.ts';
import { EScreens } from 'common/components/main-menu/enums/main.menu.enums.ts';
import { headerConfigAtom } from 'common/stores/header.store.ts';
import { mainMenuScreenAtom } from 'common/stores/main-menu.store.ts';
import BackArrowIcon from 'assets/back-arrow.icon.svg?react';

const Header = (): ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const setMainMenuScreen = useSetAtom(mainMenuScreenAtom);
  const {
    backLink,
    title,
    isMenuVisible = true,
  } = useAtomValue(headerConfigAtom);

  return (
    <SContainer>
      <SLinkContainer>
        {backLink && (
          <SLink
            id="navigate-back"
            onClick={() => {
              if (typeof backLink === 'string' || typeof backLink === 'number') {
                navigate(backLink as unknown as string);
              } else {
                backLink();
              }
            }}
          >
            <BackArrowIcon />
          </SLink>
        )}
      </SLinkContainer>
      <div>
        {title && (
          <STitle className="p2">{t(title)}</STitle>
        )}
      </div>
      <SMenuContainer id="main-menu">
        {isMenuVisible && (
          <SMenuIcon
            onClick={() => {
              setMainMenuScreen(EScreens.Main);
            }}
          />
        )}
      </SMenuContainer>
    </SContainer>
  );
};

export default Header;
