import { UiNode } from '@ory/kratos-client/api.ts';
import { LoaderFunctionArgs } from 'react-router-dom';
import { EUrlQueryParams } from 'common/enums/url-query-params.enums.ts';
import { captureError, IError } from 'common/utils/error.utils.ts';
import { authCreateRegistrationFlowService } from 'domains/auth/services/auth.service.ts';
import { TUiNodeAttributes } from 'domains/auth/types/auth.types.ts';
import { ILoginPageLoaderData } from 'pages/auth/login/interfaces/login.interfaces.ts';

export const loginPageLoader = async ({ request }: LoaderFunctionArgs): Promise<ILoginPageLoaderData> => {
  try {
    const returnToQueryParamValue = new URL(request.url).searchParams.get(EUrlQueryParams.ReturnTo) ?? '';
    const registrationFlowData = await authCreateRegistrationFlowService({
      ...(returnToQueryParamValue ? { afterVerificationReturnTo: returnToQueryParamValue } : null),
      ...(returnToQueryParamValue ? { returnTo: returnToQueryParamValue } : null),
    });
    const initialValues = registrationFlowData.ui.nodes.reduce(
      (acc: Record<string, string | number>, node: UiNode) => {
        const attributes = node.attributes as TUiNodeAttributes;

        return {
          ...acc,
          [attributes.name]: attributes.value as string,
        };
      },
      {},
    );

    return {
      registrationFlowData,
      initialValues,
    };
  } catch (error: unknown) {
    captureError(error as IError);

    return {
      registrationFlowData: {},
      initialValues: {},
    };
  }
};
