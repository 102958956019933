import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { SContent, SSuccessIcon, STitle } from 'pages/auth/successful-login/successful-login.page.styles.ts';

const SuccessfulLoginPage = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <SContent>
      <SSuccessIcon />
      <STitle>{t('successfulLogin.title')}</STitle>
    </SContent>
  );
};

export default SuccessfulLoginPage;
