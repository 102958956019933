export enum EThemes {
  Dark = 'dark',
  Light = 'light',
}

export enum EThemesSelector  {
  Dark = 'dark',
  Light = 'light',
  Auto = 'auto',
}
