import { createQueryKeys } from '@lukemorales/query-key-factory';
import { Session } from '@ory/kratos-client';
import { FlowError } from '@ory/kratos-client/api.ts';
import { EQueryKeys } from 'common/enums/query-keys.enums.ts';
import { authToSessionService, getFlowErrorService } from 'domains/auth/services/auth.service.ts';

export const authQueryKeys = createQueryKeys(EQueryKeys.AuthQueryKeys, {
  getToSession: () => ({
    queryKey: ['get-to-session'],
    queryFn: (): Promise<Session> => authToSessionService(),
  }),
  getFlowError: (errorId: string) => ({
    queryKey: ['get-flow-error', errorId],
    queryFn: (): Promise<FlowError> => getFlowErrorService(errorId),
  }),
});
