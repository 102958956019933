import { ReactElement } from 'react';
import { Outlet } from 'react-router-dom';
import Header from 'common/components/header/header.component.tsx';
import MainMenu from 'common/components/main-menu/main-menu.component.tsx';
import { SContainer, SOutletContainer } from './main.layout.styles.ts';

const MainLayout = (): ReactElement => (
  <SContainer>
    <Header />
    <SOutletContainer>
      <Outlet />
    </SOutletContainer>
    <MainMenu />
  </SContainer>
);

export default MainLayout;
