import { ReactElement } from 'react';
import { TooltipProps } from 'antd/es/tooltip';
import { GlobalStyle, STooltip } from './tooltip.component.styles.ts';

const Tooltip = (props: TooltipProps): ReactElement => (
  <>
    <GlobalStyle />
    <STooltip {...props} overlayClassName="custom-tooltip" />
  </>
);

export default Tooltip;
