import styled from 'styled-components';
import MenuIcon from 'assets/menu.icon.svg?react';

export const SContainer = styled.div`
  padding: 16px 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`;

export const SLinkContainer = styled.div`
  height: 24px;
  width: 24px;

  svg {
    path {
      stroke: ${props => props.theme.themeV3.colors.grey700};
    }
  }
`;

export const SLink = styled.div`
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
`;

export const STitle = styled.p`
  text-align: center;
`;

export const SMenuContainer = styled.div`
  width: 24px;
  height: 24px;
  
  svg {
    path {
      stroke: ${props => props.theme.themeV3.colors.grey700};
    }
  }
`;

export const SMenuIcon = styled(MenuIcon)`
  cursor: pointer;
`;
