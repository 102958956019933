import styled from 'styled-components';
import { DatePicker } from 'antd';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';

const MyDatePicker = DatePicker.generatePicker<Date>(dateFnsGenerateConfig);

export const SDatePicker = styled(MyDatePicker)`
  width: 100%;
  border-radius: 8px;
  height: 56px;
  border: 0;
  background: ${props => props.theme.themeV3.colors.grey200};
  padding: 16px;

  &:hover,
  &:focus,
  &:focus-within {
    border: 0;
    color: ${props => props.theme.themeV3.colors.grey700};
    background: ${props => props.theme.themeV3.colors.grey200};
    box-shadow: none;
  }
  
  .ant-picker-input {
    display: flex;
    
    input {
      color: ${props => props.theme.themeV3.colors.grey700};
      font-family: "Plus Jakarta Sans", sans-serif;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      
      &::placeholder {
        color: ${props => props.theme.themeV3.colors.grey400};
        font-family: "Plus Jakarta Sans", sans-serif;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
      }
    }
    
    .ant-picker-suffix {
      svg {
        path {
          fill: ${props => props.theme.themeV3.colors.grey700};
        }
      }
    }
  }
`;
