import { ReactElement } from 'react';
import { useSetAtom, useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { mainMenuScreenAtom } from 'common/stores/main-menu.store.ts';
import { profileConfigAtom } from 'common/stores/profile-config.store.ts';
import { themeSelectorAtom } from 'common/stores/theme.store.ts';
import { captureError, IError } from 'common/utils/error.utils.ts';
import { authLogoutFlowService } from 'domains/auth/services/auth.service.ts';
import { ROUTES_MAPPING } from 'navigation/constants/route.constants.ts';
import DocumentsIcon from 'assets/main-menu/documents.icon.svg?react';
import LanguageIcon from 'assets/main-menu/language.icon.svg?react';
import LogOutIcon from 'assets/main-menu/log-out.icon.svg?react';
import ThemeModeIcon from 'assets/main-menu/theme-mode.icon.svg?react';
import TransactionHistoryIcon from 'assets/main-menu/transaction-history.icon.svg?react';
import { THEME_SELECTOR_CONFIG } from 'theme/constants/theme.constants.ts';
import { APP_CONFIG, ELanguages } from '../../../../app.config.ts';
import MainMenuNavItem from '../components/main-menu-nav-item/main-menu-nav-item.compoinent.tsx';
import { EScreens } from '../enums/main.menu.enums.ts';
import {
  SMainMenuCard,
  SMainMenuScreenContainer,
  SMainMenuScreenTitle,
} from '../main-menu.component.styles.ts';

const MainMenuScreen = (): ReactElement => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const location = useLocation();
  const setMainMenuScreen = useSetAtom(mainMenuScreenAtom);
  const themeSelectorValue = useAtomValue(themeSelectorAtom);
  const profileConfig = useAtomValue(profileConfigAtom);
  const isAuthenticatedUser = profileConfig.userIsAuthorized;

  return (
    <SMainMenuScreenContainer>
      <SMainMenuScreenTitle>
        {t('mainMenu.screen.main.title')}
      </SMainMenuScreenTitle>
      {(isAuthenticatedUser && location.pathname !== ROUTES_MAPPING.PROTECTED.INTERACTIVE_FLOW) && (
        <SMainMenuCard>
          <MainMenuNavItem
            icon={<TransactionHistoryIcon />}
            label={t('mainMenu.screen.main.nav.transactions')}
            navClickHandler={() => {
              setMainMenuScreen(null);
              navigate(ROUTES_MAPPING.PROTECTED.TRANSACTIONS);
            }}
          />
        </SMainMenuCard>
      )}

      <SMainMenuCard>
        <MainMenuNavItem
          icon={<LanguageIcon />}
          label={t('mainMenu.screen.main.nav.language')}
          selectedText={APP_CONFIG.LOCALES[i18n.language as ELanguages].label}
          navClickHandler={() => {
            setMainMenuScreen(EScreens.Language);
          }}
        />
        <MainMenuNavItem
          icon={<ThemeModeIcon />}
          label={t('mainMenu.screen.main.nav.themeMode')}
          selectedText={t(THEME_SELECTOR_CONFIG[themeSelectorValue].label)}
          navClickHandler={() => {
            setMainMenuScreen(EScreens.Theme);
          }}
        />
      </SMainMenuCard>

      <SMainMenuCard>
        <MainMenuNavItem
          icon={<DocumentsIcon />}
          label={t('mainMenu.screen.main.nav.legalAndPolicies')}
          navClickHandler={() => {
            setMainMenuScreen(EScreens.LegalAndPolicies);
          }}
        />
      </SMainMenuCard>

      {(isAuthenticatedUser && location.pathname !== ROUTES_MAPPING.PROTECTED.INTERACTIVE_FLOW) && (
        <SMainMenuCard>
          <MainMenuNavItem
            icon={<LogOutIcon />}
            label={t('mainMenu.screen.main.nav.logOut')}
            navClickHandler={async () => {
              try {
                setMainMenuScreen(null);
                await authLogoutFlowService(navigate);
              } catch (error) {
                captureError(error as IError);
              }
            }}
          />
        </SMainMenuCard>
      )}
    </SMainMenuScreenContainer>
  );
};

export default MainMenuScreen;
