export enum EUrlQueryParams {
  ReturnTo = 'return_to',
  Flow = 'flow',
  Id = 'id',
  AppId = 'app_id',
  Code = 'code',
  Message = 'message',
  InteractiveId = 'interactive-id',
  Callback = 'callback',
  OnChangeCallback = 'on_change_callback',
  IframeUrl = 'iframe-url',
  Amount = 'amount',
  PaymentMethod = 'payment-method',
  Slt = 'slt',
  Slsesid = 'slsesid',
  IsHeaderDisabled = 'isHeaderDisabled',
  Signature = 'signature',
  Redirect = 'redirect',
  Theme = 'theme',
  Language = 'language',
}
