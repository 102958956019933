import {
  InfiniteData
} from '@tanstack/react-query';
import { queryKeysClient } from 'common/clients/query-key.client.ts';
import { queryClient } from 'common/clients/query.client.ts';
import { ELocalStorageKeys } from 'common/enums/localStorage.enums.ts';
import { EQueryKeys } from 'common/enums/query-keys.enums.ts';
import { captureError, IError } from 'common/utils/error.utils.ts';
import { ETransactionsDataParams, ETransactionStatus } from 'domains/transaction/enums/transaction.enums.ts';
import {
  ITransactionsDataParams,
  ITransactionsDataRes,
} from 'domains/transaction/interfaces/transaction.interfaces.ts';
import { IUserWalletsDataRes } from 'domains/wallet/inrefaces/wallet.interfaces.ts';
import {
  FILTER_STATUS_MAPPING
} from 'pages/transactions/components/filter-bottom-sheet/filter-bottom-sheet.constants.ts';
import { ITransactionsPageLoaderData } from './interfaces/transactions.interfaces.ts';

export const transactionsPageLoader = async (): Promise<ITransactionsPageLoaderData | Response> => {
  try {
    const PAGE_LIMIT = 20;

    const getFiltersData = localStorage.getItem(ELocalStorageKeys.TransactionFilters);
    let params = {};

    if (getFiltersData) {
      const filtersData = JSON.parse(getFiltersData) as Record<ETransactionsDataParams, string>;
      params = Object.entries(filtersData).reduce((acc, [key, value]) => ({
        ...acc,
        [key]: Array.isArray(value) ? value.toString() : value,
      }), {}) as ITransactionsDataParams;
    } else {
      // Default params.
      const defaultStatusesValue = FILTER_STATUS_MAPPING
        .filter((filter: { value: unknown }) => filter.value !== ETransactionStatus.Incomplete)
        .map((filter) => filter.value)
        .toString();

      params = {
        [ETransactionsDataParams.Status]: defaultStatusesValue,
      };
    }

    const transactionsDataQuery = queryKeysClient[EQueryKeys.TransactionKeys].getTransactionsData({
      ...params,
      limit: PAGE_LIMIT,
    });
    const transactionsData: InfiniteData<ITransactionsDataRes> =
      // @ts-expect-error Hard to resolve
      await queryClient.fetchInfiniteQuery(transactionsDataQuery);

    const walletsDataQuery = queryKeysClient[EQueryKeys.WalletKeys].getUserWalletsData();
    const walletsData: IUserWalletsDataRes = await queryClient.getQueryData(walletsDataQuery.queryKey)
      ?? await queryClient.fetchQuery(walletsDataQuery);

    return {
      initialParams: params,
      transactionsData,
      walletsData,
    };
  } catch (e: unknown) {
    const error = e as {
      response: {
        status: number;
      }
    };

    captureError(error as IError);

    return {
      initialParams: {},
      transactionsData: undefined,
      walletsData: {},
    };
  }
};
