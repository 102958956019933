import { Checkbox } from 'antd';
import styled from 'styled-components';

export const SChipsGroup = styled(Checkbox.Group)`
  display: flex;
  flex-direction: row;
  gap: 8px;

  .ant-checkbox-wrapper {
    display: flex;
    height: 32px;
    padding: 4px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 16px;
    border: 1px solid ${props => props.theme.themeV3.colors.grey300};
    background: ${props => props.theme.themeV3.colors.grey200};
    box-shadow: none;
  
    &.ant-checkbox-wrapper-checked {
      border: 1px solid ${props => props.theme.themeV3.colors.green300};
      background: ${props => props.theme.themeV3.colors.green300};
    }
    
    span {
      padding: 0;
      color: ${props => props.theme.themeV3.colors.grey700};
      font-variant-numeric: lining-nums tabular-nums;
      font-family: "Plus Jakarta Sans", sans-serif;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
    }
  
    &:after {
      display: none;
    }
    
    .ant-checkbox {
      display: none;
    }
  }
`;
