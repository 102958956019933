export const ENV_API_POSTFIX = import.meta.env.VITE_API_POSTFIX || '';
export const ENV_SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN || '';
export const ENV_NAME = import.meta.env.VITE_ENV_NAME || '';
export const ENV_CI_RELEASE = import.meta.env.TAG_SR || '';
export const ENV_SMARTLOOK_TOKEN = import.meta.env.VITE_SMARTLOOK_TOKEN || '';

export const IS_PRODUCTION = ENV_NAME === 'production';
export const IS_STAGE = ENV_NAME === 'stage';
export const IS_LOCAL = ENV_NAME === 'local';

