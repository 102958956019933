import { ReactElement, useCallback, useEffect, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Loader } from 'common/components/loader/loader.component.tsx';
import { ELocalStorageKeys } from 'common/enums/localStorage.enums.ts';
import { EUrlQueryParams } from 'common/enums/url-query-params.enums.ts';
import { captureError, IError } from 'common/utils/error.utils.ts';
import { EAction } from 'domains/auth/enums/auth.enums.ts';
import { lightWalkThroughInteractiveService } from 'domains/auth/services/auth.service.ts';
import { useGetOrderFlowDataQuery } from 'domains/on-off-ramp/queries/on-off-ramp.query.ts';
import { ROUTES_MAPPING } from 'navigation/constants/route.constants.ts';

const DecisionFlowPage = (): ReactElement => {
  const navigate = useNavigate();
  const effectRan = useRef(false);
  const [searchParams] = useSearchParams();
  const signatureQueryParamValue = searchParams.get(EUrlQueryParams.Signature) ?? '';
  const interactiveIdQueryParamValue = searchParams.get(EUrlQueryParams.InteractiveId) ?? '';
  const callbackQueryParamValue = searchParams.get(EUrlQueryParams.Callback) ?? '';
  const onChangeCallbackQueryParamValue = searchParams.get(EUrlQueryParams.OnChangeCallback) ?? '';

  const {
    data: orderFlowData,
    isError: isOrderFlowDataError ,
    error: orderFlowDataError,
  } = useGetOrderFlowDataQuery(
    {
      signature: signatureQueryParamValue,
    },
    {
      enabled: !interactiveIdQueryParamValue && !!signatureQueryParamValue,
    }
  );

  useEffect(() => {
    if (isOrderFlowDataError) {
      captureError(orderFlowDataError as IError);
    }
  }, [isOrderFlowDataError, orderFlowDataError]);

  const startInteractive = useCallback( async (url: string): Promise<void> => {
    const { url: nextUrl, action } = await lightWalkThroughInteractiveService(url);

    if (nextUrl && action === EAction.Finish) {
      navigate(nextUrl);
    } else if (nextUrl) {
      await startInteractive(`${nextUrl}?redirect=false`);
    }
  }, [navigate]);

  useEffect((): () => void => {
    if (interactiveIdQueryParamValue) {
      localStorage.setItem(ELocalStorageKeys.TransactionGUID, interactiveIdQueryParamValue);

      const startInteractivePath = `/sep0024/transactions/start/interactive/${interactiveIdQueryParamValue}`;
      const startInteractiveUrl = new URL(startInteractivePath, window.location.origin);
      startInteractiveUrl.searchParams.set(EUrlQueryParams.Redirect, 'false');

      if (callbackQueryParamValue) {
        startInteractiveUrl.searchParams.set(EUrlQueryParams.Callback, callbackQueryParamValue);
      }

      if (onChangeCallbackQueryParamValue) {
        startInteractiveUrl.searchParams.set(EUrlQueryParams.OnChangeCallback, onChangeCallbackQueryParamValue);
      }

      const startInteractiveFromUrl = decodeURIComponent(
        `${startInteractiveUrl.pathname}?${startInteractiveUrl.searchParams.toString()}`
      );

      if (!effectRan.current) {
        startInteractive(startInteractiveFromUrl)
          .then(res => res)
          .catch(error => {
            captureError(error as IError);
            navigate(ROUTES_MAPPING.NO_MATCH);
          });
      }
    } else if (orderFlowData) {
      navigate(ROUTES_MAPPING.FPF_ORDER_FORM, {
        state: {
          signature: signatureQueryParamValue,
          fpfOrderFormUrl: orderFlowData.url,
        }
      });
    }

    return (): void => {
      effectRan.current = true;
    };
  }, [
    signatureQueryParamValue,
    orderFlowData,
    callbackQueryParamValue,
    interactiveIdQueryParamValue,
    navigate,
    onChangeCallbackQueryParamValue,
    startInteractive
  ]);

  return (
    <Loader fullscreen />
  );
};

export default DecisionFlowPage;
