import enIcon from 'assets/flags/gb.svg?react';
import ptIcon from 'assets/flags/pt.svg?react';

export enum ELanguages {
  En = 'en',
  Pt = 'pt',
}

export const APP_CONFIG = {
  LOCALE_DEFAULT: ELanguages.En,
  FALLBACK_LOCALE: ELanguages.En,
  LOCALES: {
    [ELanguages.En]: {
      key: ELanguages.En,
      label: 'English',
      flagIcon: enIcon,
    },
    [ELanguages.Pt]: {
      key: ELanguages.Pt,
      label: 'Português',
      flagIcon: ptIcon,
    },
  },
};
