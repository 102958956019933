import { Session } from '@ory/kratos-client';
import i18n from 'i18next';
import { mainStore } from 'common/clients/jotai-store.client.ts';
import { queryKeysClient } from 'common/clients/query-key.client.ts';
import { queryClient } from 'common/clients/query.client.ts';
import { EQueryKeys } from 'common/enums/query-keys.enums.ts';
import { profileConfigAtom } from 'common/stores/profile-config.store.ts';
import { captureError, IError } from 'common/utils/error.utils.ts';
import { IUserProfileConfigRes } from 'domains/user/interfaces/user.interfaces.ts';
import { IWalletDataRes } from 'domains/wallet/inrefaces/wallet.interfaces.ts';
import { APP_CONFIG } from '../../app.config.ts';

export interface INeutralRouteGuardRootLoader {
  getWalletData: IWalletDataRes | undefined;
  getToSessionData: Session | undefined;
  userIsAuthorized: boolean;
  getUserProfileConfigData: IUserProfileConfigRes | undefined;
}

export const neutralRouteGuardRootLoader = async (): Promise<INeutralRouteGuardRootLoader> => {
  let getToSessionData: Session | undefined;

  try {
    const getToSessionQuery = queryKeysClient[EQueryKeys.AuthQueryKeys].getToSession();
    getToSessionData =
      await queryClient.getQueryData(getToSessionQuery.queryKey)
      ?? await queryClient.fetchQuery(getToSessionQuery);
  } catch (e) {
    const error = e as {
      response?: {
        status?: number;
      };
    };

    if (error.response?.status !== 401) {
      captureError(error as IError);
    }
  }

  try {
    const getWalletDataQuery = queryKeysClient[EQueryKeys.WalletKeys].getWalletData();
    const getWalletData: IWalletDataRes =
      await queryClient.getQueryData(getWalletDataQuery.queryKey)
      ?? await queryClient.fetchQuery(getWalletDataQuery);

    let userIsAuthorized = false;
    let getUserProfileConfigData: IUserProfileConfigRes | undefined;

    if (!(i18n.language in APP_CONFIG.LOCALES)) {
      await i18n.changeLanguage(APP_CONFIG.LOCALE_DEFAULT);
    }

    // Check if user is authorized.
    if (getToSessionData?.active) {
      userIsAuthorized = true;

      // const getUserProfileConfigQuery = queryKeysClient[EQueryKeys.UserKeys].getUserProfileConfig();
      // getUserProfileConfigData =
      //   await queryClient.getQueryData(getUserProfileConfigQuery.queryKey)
      //   ?? await queryClient.fetchQuery(getUserProfileConfigQuery);

      // Set lang from config
      if (getUserProfileConfigData?.lang && getUserProfileConfigData.lang in APP_CONFIG.LOCALES) {
        await i18n.changeLanguage(getUserProfileConfigData.lang);
      }

      // Set profile data to store
      mainStore.set(profileConfigAtom, (data) => ({
        ...data,
        userIsAuthorized: true,
        profile: getUserProfileConfigData,
      }));
    }

    return {
      getWalletData,
      getToSessionData,
      userIsAuthorized,
      getUserProfileConfigData,
    };
  } catch (error: unknown) {
    captureError(error as IError);

    return {
      getWalletData: undefined,
      getToSessionData: undefined,
      userIsAuthorized: false,
      getUserProfileConfigData: undefined,
    };
  }
};
