import styled from 'styled-components';
import { ETransactionStatus } from 'domains/transaction/enums/transaction.enums';
import {
  ETransactionStatusBadgeType
} from './enums/transaction-status-badge.enums.ts';

export const SStatusContainer = styled.div`
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 4px 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  width: fit-content;
  text-transform: capitalize;
  
  &.${ETransactionStatusBadgeType.Simple} {
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    padding: 0;
    background: transparent !important;

    svg {
      display: none;
    }
    
    &.${ETransactionStatus.Refunded},
    &.${ETransactionStatus.Completed} {
      color: ${(props) => props.theme.themeV3.colors.green400};
    }

    &.${ETransactionStatus.Error} {
      color: ${(props) => props.theme.themeV3.colors.red400};
    }
    
    &.pending {
      color: ${(props) => props.theme.themeV3.colors.yellow400};
    }

    &.default-status,
    &.${ETransactionStatus.Expired},
    &.${ETransactionStatus.Incomplete} {
      color: ${(props) => props.theme.themeV3.colors.grey400};
    }
  }

  &.${ETransactionStatus.Refunded},
  &.${ETransactionStatus.Completed} {
    color: ${(props) => props.theme.themeV3.colors.green500};
    background: ${(props) => props.theme.themeV3.colors.green200};

    svg {
      path.stroke {
        stroke: ${(props) => props.theme.themeV3.colors.green500};
      }

      path.fill {
        fill: ${(props) => props.theme.themeV3.colors.green500};
      }
    }
  }
  
  &.${ETransactionStatus.Error} {
    color: ${(props) => props.theme.themeV3.colors.red500};
    background: ${(props) => props.theme.themeV3.colors.red200};

    svg {
      path.stroke, circle.stroke {
        stroke: ${(props) => props.theme.themeV3.colors.red500};
      }
    }
  }
  
  &.pending {
    color: ${(props) => props.theme.themeV3.colors.yellow500};
    background: ${(props) => props.theme.themeV3.colors.yellow200};

    svg {
      path, circle {
        stroke: ${(props) => props.theme.themeV3.colors.yellow500};
      }
    }
  }

  &.default-status,
  &.${ETransactionStatus.Expired},
  &.${ETransactionStatus.Incomplete} {
    color: ${(props) => props.theme.themeV3.colors.grey500};
    background: ${(props) => props.theme.themeV3.colors.grey200};

    svg {
      path.stroke {
        stroke: ${(props) => props.theme.themeV3.colors.grey500};
      }

      path.fill {
        fill: ${(props) => props.theme.themeV3.colors.grey500};
      }
    }
  }
`;
