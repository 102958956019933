import { LoaderFunctionArgs, redirect } from 'react-router-dom';
import { queryKeysClient } from 'common/clients/query-key.client.ts';
import { queryClient } from 'common/clients/query.client.ts';
import { EQueryKeys } from 'common/enums/query-keys.enums.ts';
import { EUrlQueryParams } from 'common/enums/url-query-params.enums.ts';
import { captureError, IError } from 'common/utils/error.utils.ts';
import { ITransactionInfoDataRes } from 'domains/transaction/interfaces/transaction.interfaces.ts';
import { ROUTES_MAPPING } from 'navigation/constants/route.constants.ts';
import { IFinalStepPageLoaderData } from './interfaces/final-step.interfaces.ts';

export const finalStepLoader = async (
  { request }: LoaderFunctionArgs
): Promise<IFinalStepPageLoaderData | Response> => {
  try {
    const idQueryParamValue = new URL(request.url).searchParams.get(EUrlQueryParams.Id) ?? '';
    if (!idQueryParamValue) {
      return redirect(ROUTES_MAPPING.NO_MATCH);
    }

    const transactionDataQuery = queryKeysClient[EQueryKeys.TransactionKeys].getTransactionInfoData(idQueryParamValue);
    const transactionData: ITransactionInfoDataRes =
      (await queryClient.getQueryData(transactionDataQuery.queryKey)) ??
      (await queryClient.fetchQuery(transactionDataQuery));

    return {
      transactionData,
    };
  } catch (e: unknown) {
    const error = e as {
      response: {
        status: number;
      }
    };

    captureError(error as IError);

    if (error.response.status === 404) {
      return redirect(ROUTES_MAPPING.NO_MATCH);
    }

    return {
      transactionData: {},
    };
  }
};
