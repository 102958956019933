import { ReactElement } from 'react';
import {
  SMainMenuNavItemContainer,
  SMainMenuNavItemIcon,
  SMainMenuNavItemLabel,
  SMainMenuNavItemSelectedValue,
  SNavArrowIcon,
} from './main-menu-nav-item.component.styles.ts';

interface IProps {
  icon: ReactElement;
  label: string;
  selectedText?: string;
  navClickHandler: () => void;
}

const MainMenuNavItem = (props: IProps): ReactElement => {
  const {
    icon,
    label,
    selectedText,
    navClickHandler,
  } = props;

  return (
    <SMainMenuNavItemContainer onClick={navClickHandler}>
      <SMainMenuNavItemIcon>{icon}</SMainMenuNavItemIcon>
      <SMainMenuNavItemLabel className="p3">{label}</SMainMenuNavItemLabel>
      {selectedText && (
        <SMainMenuNavItemSelectedValue className="p3">{selectedText}</SMainMenuNavItemSelectedValue>
      )}
      <SNavArrowIcon />
    </SMainMenuNavItemContainer>
  );
};

MainMenuNavItem.defaultProps = {
  selectedText: undefined,
};

export default MainMenuNavItem;
