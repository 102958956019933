import { useMutation, UseMutationResult, useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { queryKeysClient } from 'common/clients/query-key.client.ts';
import { EQueryKeys } from 'common/enums/query-keys.enums.ts';
import {
  IOffRampReq,
  IOffRampRes,
  IOnRampReq,
  IOnRampRes,
  IOrderFlowDataReq,
  IOrderFlowDataRes,
} from 'domains/on-off-ramp/interfaces/on-off-ramp.interfaces.ts';
import { postOffRampService, postOnRampService } from 'domains/on-off-ramp/services/on-off-ramp.service.ts';

export const useGetOrderFlowDataQuery = (
  reqData: IOrderFlowDataReq,
  options?: Omit<UseQueryOptions<IOrderFlowDataRes>, 'queryKey'>,
): UseQueryResult<IOrderFlowDataRes> =>
  useQuery({
    ...options,
    ...(queryKeysClient[EQueryKeys.OnOffRamp]
      .getOrderFlowData(reqData) as unknown as UseQueryOptions<IOrderFlowDataRes>),
  });

export const useOnRampMutation = (): UseMutationResult<
  IOnRampRes,
  Error,
  { reqData: IOnRampReq }
> =>
  useMutation({
    mutationFn: ({ reqData }: { reqData: IOnRampReq }) =>
      postOnRampService(reqData),
  });

export const useOffRampMutation = (): UseMutationResult<
  IOffRampRes,
  Error,
  { reqData: IOffRampReq }
> =>
  useMutation({
    mutationFn: ({ reqData }: { reqData: IOffRampReq }) =>
      postOffRampService(reqData),
  });
