import { atom } from 'jotai';
import { IUserProfileConfigRes } from 'domains/user/interfaces/user.interfaces.ts';

interface IProfileConfigAtomData {
  userIsAuthorized: boolean;
  profile?: IUserProfileConfigRes;
}

export const profileConfigAtom = atom<IProfileConfigAtomData>({
  userIsAuthorized: false,
});
