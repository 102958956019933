import { ReactElement, useState } from 'react';
import * as Sentry from '@sentry/react';
import { Alert } from 'antd';
import { Outlet } from 'react-router-dom';

const ErrorBoundary = (): ReactElement => {
  const [err, setErr] = useState<null | { error: unknown; componentStack: string  | undefined }>(null);
  const onError = (error: unknown, componentStack: string | undefined): void => {
    setErr({ error, componentStack });
  };
  return (
    <Sentry.ErrorBoundary
      onError={onError}
      fallback={
        <Alert showIcon message="General error" description={String(err?.error)} type="error" style={{ margin: 20 }} />
      }
      showDialog
    >
      <Outlet />
    </Sentry.ErrorBoundary>
  );
};

export default ErrorBoundary;
