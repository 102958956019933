import styled from 'styled-components';

export const SContainer = styled.div`
  padding: 16px 16px 32px;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const SOutletContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
`;
