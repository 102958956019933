import { ReactElement, useEffect } from 'react';
import { RadioChangeEvent } from 'antd';
import { useSetAtom , useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { EScreens } from 'common/components/main-menu/enums/main.menu.enums.ts';
import { mainMenuBackBtnAtom } from 'common/stores/main-menu.store.ts';
import { themeSelectorAtom } from 'common/stores/theme.store.ts';
import { THEME_SELECTOR_CONFIG } from 'theme/constants/theme.constants.ts';
import { EThemesSelector } from 'theme/enums/theme.enums.ts';
import {
  SMainMenuCard,
  SMainMenuScreenContainer,
  SMainMenuScreenTitle,
  SMainMenuSelector,
  SMainMenuSelectorFilledIconWrapper,
  SMainMenuSelectorGroup,
  SMainMenuSelectorLabel,
} from '../main-menu.component.styles.ts';

const ThemeMainMenuScreen = (): ReactElement => {
  const setMainMenuBack = useSetAtom(mainMenuBackBtnAtom);
  const { t } = useTranslation();
  const [themeSelectorValue, setThemeSelectorValue] = useAtom(themeSelectorAtom);

  useEffect(() => {
    setMainMenuBack(EScreens.Main);
  }, [setMainMenuBack]);

  const onChangeThemeHandler = (event: RadioChangeEvent): void => {
    const theme = event.target.value as EThemesSelector;
    setThemeSelectorValue(theme);
  };

  return (
    <SMainMenuScreenContainer>
      <SMainMenuScreenTitle>
        {t('mainMenu.screen.theme.title')}
      </SMainMenuScreenTitle>

      <SMainMenuCard>
        <SMainMenuSelectorGroup
          defaultValue={themeSelectorValue}
          onChange={onChangeThemeHandler}
        >
          {Object.values(THEME_SELECTOR_CONFIG).map(({ key, label, icon: Icon }) =>
            <SMainMenuSelector value={key} key={key}>
              <SMainMenuSelectorFilledIconWrapper>
                <Icon />
              </SMainMenuSelectorFilledIconWrapper>
              <SMainMenuSelectorLabel className="p3">
                {t(label)}
              </SMainMenuSelectorLabel>
            </SMainMenuSelector>
          )}
        </SMainMenuSelectorGroup>
      </SMainMenuCard>
    </SMainMenuScreenContainer>
  );
};

export default ThemeMainMenuScreen;
