import styled from 'styled-components';

export const SLogoContainer = styled.div`
  padding: 40px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
  width: 100%;
`;

export const SApsLogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  
  svg {
    width: 84px;
    height: 32px;
  }
`;

export const SSingleApsLogoContainer = styled(SApsLogoContainer)`
  justify-content: center;
`;

export const SWalletLogoDivider = styled.div`
  height: 24px;
  width: 1px;
  background: ${props => props.theme.themeV3.colors.grey600};
`;

export const SWalletLogoContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  
  svg {
    width: auto;
    height: 18px;
  }
`;
