import { ReactElement, useEffect } from 'react';
import { RadioChangeEvent } from 'antd';
import {
  useSetAtom,
  // useAtomValue
} from 'jotai';
import { useTranslation } from 'react-i18next';
import { EScreens } from 'common/components/main-menu/enums/main.menu.enums.ts';
import { mainMenuBackBtnAtom } from 'common/stores/main-menu.store.ts';
// import { profileConfigAtom } from 'common/stores/profile-config.store.ts';
import { captureError, IError } from 'common/utils/error.utils.ts';
// import { usePostUserProfileConfigMutation } from 'domains/user/queries/user.query.ts';
import { APP_CONFIG, ELanguages } from '../../../../app.config.ts';
import {
  SMainMenuCard,
  SMainMenuScreenContainer,
  SMainMenuScreenTitle,
  SMainMenuSelector,
  SMainMenuSelectorSimpleIconWrapper,
  SMainMenuSelectorGroup,
  SMainMenuSelectorLabel,
} from '../main-menu.component.styles.ts';

const LanguagesMainMenuScreen = (): ReactElement => {
  const setMainMenuBack = useSetAtom(mainMenuBackBtnAtom);
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  // const profileConfig = useAtomValue(profileConfigAtom);
  // const isAuthenticatedUser = profileConfig.userIsAuthorized;
  // const { mutateAsync: mutateAsyncPostUserProfileConfigMutation } = usePostUserProfileConfigMutation();

  useEffect(() => {
    setMainMenuBack(EScreens.Main);
  }, [setMainMenuBack]);

  const onChangeLanguageHandler = async (event: RadioChangeEvent): Promise<void> => {
    const language = event.target.value as ELanguages;

    try {
      await i18n.changeLanguage(language);

      // if (isAuthenticatedUser) {
      //   const reqData = {
      //     lang: language,
      //   };
      //   await mutateAsyncPostUserProfileConfigMutation({ reqData });
      // }
    } catch (error: unknown) {
      captureError(error as IError);
    }
  };

  return (
    <SMainMenuScreenContainer>
      <SMainMenuScreenTitle>
        {t('mainMenu.screen.language.title')}
      </SMainMenuScreenTitle>

      <SMainMenuCard>
        <SMainMenuSelectorGroup
          defaultValue={i18n.language}
          onChange={onChangeLanguageHandler}
        >
          {Object.values(APP_CONFIG.LOCALES).map(({ key, label, flagIcon: FlagIcon }) =>
            <SMainMenuSelector value={key} key={key}>
              <SMainMenuSelectorSimpleIconWrapper>
                <FlagIcon />
              </SMainMenuSelectorSimpleIconWrapper>
              <SMainMenuSelectorLabel className="p3">
                {label}
              </SMainMenuSelectorLabel>
            </SMainMenuSelector>
          )}
        </SMainMenuSelectorGroup>
      </SMainMenuCard>
    </SMainMenuScreenContainer>
  );
};

export default LanguagesMainMenuScreen;
