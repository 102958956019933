import { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { Session } from '@ory/kratos-client';
import { useSetAtom, useAtomValue } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { queryKeysClient } from 'common/clients/query-key.client.ts';
import { queryClient } from 'common/clients/query.client.ts';
import { setSmartlookIdentity, SMARTLOOK_ANONYMOUS_SESSION_ID } from 'common/clients/smartlook.client.ts';
import { Loader } from 'common/components/loader/loader.component.tsx';
import WalletLogo from 'common/components/wallet-logo/wallet-logo.component.tsx';
import { ENV_SMARTLOOK_TOKEN } from 'common/constants/env.constants.ts';
import { FPF_IFRAME_VALID_URLS } from 'common/constants/fpf.constants.ts';
import { EQueryKeys } from 'common/enums/query-keys.enums.ts';
import { EUrlQueryParams } from 'common/enums/url-query-params.enums.ts';
import { headerConfigAtom } from 'common/stores/header.store.ts';
import { themeNameAtom } from 'common/stores/theme.store.ts';
import { setHttps } from 'common/utils/url.utils.ts';
import { useGetUserProfileDataMutation } from 'domains/user/queries/user.query.ts';
import { IWalletDataRes } from 'domains/wallet/inrefaces/wallet.interfaces.ts';
import { IFpfOrderFormPageLocationState } from 'pages/fpf-order-form/interfaces/fpf-order-form.interfaces.ts';
import { ROUTES_MAPPING } from 'navigation/constants/route.constants.ts';
import {
  SIframe
} from './fpf-order-form.page.styles.ts';

const FpfOrderFormPage = (): ReactElement => {
  const { i18n } = useTranslation();
  const iRef = useRef<HTMLIFrameElement | null>(null);
  const navigate = useNavigate();
  const location = useLocation() as IFpfOrderFormPageLocationState;
  const { state: locationState } = location;
  const [searchParams] = useSearchParams();
  const themeName = useAtomValue(themeNameAtom);
  const [iframeUrl, setIframeUrl] = useState<string>('');
  const interactiveIdQueryParamValue = searchParams.get(EUrlQueryParams.InteractiveId) ?? '';
  const iframeUrlQueryParamValue = searchParams.get(EUrlQueryParams.IframeUrl) ?? '';
  const setHeaderConfig = useSetAtom(headerConfigAtom);
  const [isIframeLoading, setIsIframeLoading] = useState<boolean>(true);
  const getToSession = queryClient.getQueryData(queryKeysClient[EQueryKeys.AuthQueryKeys].getToSession().queryKey);
  const walletDataFromCache: IWalletDataRes | undefined = queryClient.getQueryData(
    queryKeysClient[EQueryKeys.WalletKeys].getWalletData().queryKey
  );
  const { mutateAsync: mutateAsyncGetUserProfileDatMutation } = useGetUserProfileDataMutation();

  useEffect(() => {
    const searchParams = new URLSearchParams(locationState?.fpfOrderFormUrl ?? iframeUrlQueryParamValue);
    searchParams.append(EUrlQueryParams.IsHeaderDisabled, 'true');
    searchParams.append(EUrlQueryParams.Slt, ENV_SMARTLOOK_TOKEN);
    searchParams.append(EUrlQueryParams.Slsesid, interactiveIdQueryParamValue || SMARTLOOK_ANONYMOUS_SESSION_ID);
    searchParams.append(EUrlQueryParams.Theme, themeName);
    searchParams.append(EUrlQueryParams.Language, i18n.language);
    const iframeUrl = decodeURIComponent(searchParams.toString());

    setIframeUrl(iframeUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setHeaderConfig({
      title: '',
      backLink: () => {
        const postMessageData = {
          type: 'step_back',
        };
        iRef.current?.contentWindow?.postMessage(JSON.stringify(postMessageData), '*');
      },
    });

    return () => {
      setHeaderConfig({
        title: '',
        backLink: -1,
      });
    };
  }, [interactiveIdQueryParamValue, setHeaderConfig]);

  useEffect(() => {
    const isValidIframeUrl = FPF_IFRAME_VALID_URLS.some(substring => iframeUrlQueryParamValue.includes(substring));

    if (iframeUrlQueryParamValue && !isValidIframeUrl) {
      navigate(ROUTES_MAPPING.NO_MATCH);
    }
  }, [iframeUrlQueryParamValue, navigate]);

  useEffect(() => {
    const postMessageData = {
      type: 'set_lang',
      message: i18n.language,
    };
    iRef.current?.contentWindow?.postMessage(JSON.stringify(postMessageData), '*');
  }, [i18n.language]);

  useEffect(() => {
    const postMessageData = {
      type: 'theme',
      message: themeName,
    };
    iRef.current?.contentWindow?.postMessage(JSON.stringify(postMessageData), '*');
  }, [themeName]);

  const postMessageDataHandler = useCallback(async (eventData: MessageEvent) => {
    if (typeof eventData.data === 'string') {
      const data = JSON.parse(eventData.data as unknown as string) as {
        type: string;
        message: string;
      };

      const {
        type,
        message,
      } = data;

      if (type === 'submit_order_form') {
        if (getToSession) {
          let profileData;

          try {
            setIsIframeLoading(true);

            profileData = await mutateAsyncGetUserProfileDatMutation({});

            setIsIframeLoading(false);
          } catch (error) {
            console.error(error);
            setIsIframeLoading(false);
          }

          const { identity } = getToSession as Session;
          const userIdentityId = identity?.id;
          const identityTraits = identity?.traits as { email?: string } | undefined;
          const userEmail = identityTraits?.email ?? '';

          setSmartlookIdentity({
            interactiveID: interactiveIdQueryParamValue,
            userIdentityId,
            userEmail,
            kyc_status: profileData?.info.kyc_status,
            kyc_level: profileData?.info.kyc_level,
            user_city: profileData?.info.user_city,
            user_country: profileData?.info.country_residence,
            wallet_name: walletDataFromCache?.name,
          });
        }

        const { amount, paymentMethod } = message as unknown as {
          amount: string;
          paymentMethod: string;
          merchantExternalId: string;
        };
        const interactiveIdParam = `${EUrlQueryParams.InteractiveId}=${interactiveIdQueryParamValue}`;
        const amountParam = `${EUrlQueryParams.Amount}=${amount}`;
        const paymentMethodParam = `${EUrlQueryParams.PaymentMethod}=${paymentMethod}`;

        if (interactiveIdQueryParamValue) {
          const interactiveFlowUrl = `${window.location.origin}${ROUTES_MAPPING.PROTECTED.INTERACTIVE_FLOW}`;
          const navigateTo = `${interactiveFlowUrl}?${interactiveIdParam}&${amountParam}&${paymentMethodParam}`;

          window.location.replace(setHttps(navigateTo));
        } else if (locationState?.signature) {
          const queryParams = new URLSearchParams();
          queryParams.set(EUrlQueryParams.Amount, amount);
          queryParams.set(EUrlQueryParams.PaymentMethod, paymentMethod);
          queryParams.set(EUrlQueryParams.Signature, locationState.signature);

          navigate({
            pathname: ROUTES_MAPPING.PROTECTED.INTERACTIVE_FLOW,
            search: queryParams.toString(),
          });
        }
      }
    }
  }, [
    getToSession,
    interactiveIdQueryParamValue,
    mutateAsyncGetUserProfileDatMutation,
    navigate,
    locationState?.signature,
    walletDataFromCache
  ]);

  useEffect(() => {
    window.addEventListener('message', postMessageDataHandler);

    return () => {
      window.removeEventListener('message', postMessageDataHandler);
    };
  }, [postMessageDataHandler]);

  return (
    <>
      {(!iframeUrl || isIframeLoading) && <Loader fullscreen />}
      <WalletLogo />
      {iframeUrl && <SIframe
        ref={iRef}
        src={iframeUrl}
        onLoad={() => {
          setIsIframeLoading(false);
        }}
      />}
    </>
  );
};

export default FpfOrderFormPage;
