import { ReactElement } from 'react';
import { InputProps } from 'antd';
import { MaskedInputProps } from 'antd-mask-input/build/main/lib/MaskedInput';
import { SInput, SMaskedInput } from 'common/components/input/input.component.styles.ts';
import Cross2Icon from 'assets/cross-2.icon.svg?react';

type IProps = InputProps & Partial<MaskedInputProps> & {
  inputMode?: string;
}

const Input = (props: IProps): ReactElement => {
  const { mask } = props;

  if (mask) {
    return <SMaskedInput
      allowClear={{
        clearIcon: <Cross2Icon />,
      }}
      {...props as MaskedInputProps}
    />;
  }

  return <SInput
    allowClear={{
      clearIcon: <Cross2Icon />,
    }}
    {...props}
  />;
};

Input.defaultProps = {
  inputMode: 'text',
};

export default Input;
