import { Configuration, FrontendApi } from '@ory/kratos-client';
import { API_ENDPOINTS } from 'common/constants/api-endpoints.constants.ts';

const configuration = new Configuration({
  basePath: API_ENDPOINTS.KRATOS_SELF_SERVICE_BASE_URL,
  baseOptions: {
    withCredentials: true,
  },
});

export const kratosClient = new FrontendApi(configuration);
