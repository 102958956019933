import { ReactElement, useEffect } from 'react';
import { Session } from '@ory/kratos-client';
import * as Sentry from '@sentry/react';
import { Outlet, useLocation, useNavigation, useSearchParams } from 'react-router-dom';
import { queryKeysClient } from 'common/clients/query-key.client.ts';
import { queryClient } from 'common/clients/query.client.ts';
import { setSmartlookIdentity } from 'common/clients/smartlook.client.ts';
import { Loader } from 'common/components/loader/loader.component.tsx';
import { EQueryKeys } from 'common/enums/query-keys.enums.ts';
import { EUrlQueryParams } from 'common/enums/url-query-params.enums.ts';

const NeutralRouteGuard = (): ReactElement => {
  const { state } = useNavigation();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const interactiveIdQueryParamValue = searchParams.get(EUrlQueryParams.InteractiveId) ?? '';
  const sessionData: Session | undefined = queryClient.getQueryData(
    queryKeysClient[EQueryKeys.AuthQueryKeys].getToSession().queryKey
  );

  useEffect(() => {
    if (sessionData) {
      const { id: userGuid = '' } = sessionData.identity ?? { id: ''};
      setSmartlookIdentity({
        interactiveID: interactiveIdQueryParamValue,
        userIdentityId: userGuid,
      });
      Sentry.setContext('Transaction info', {
        ...(userGuid ? { user_guid: userGuid } : null),
        ...(interactiveIdQueryParamValue ? { transaction_guid: interactiveIdQueryParamValue } : null),
      });
    } else {
      setSmartlookIdentity({
        interactiveID: interactiveIdQueryParamValue,
      });
      Sentry.setContext('Transaction info', {
        ...(interactiveIdQueryParamValue ? { transaction_guid: interactiveIdQueryParamValue } : null),
      });
    }
  }, [searchParams, location, interactiveIdQueryParamValue, sessionData]);

  return (
    <>
      <Outlet />
      {state === 'loading' && <Loader fullscreen />}
    </>
  );
};

export default NeutralRouteGuard;
