import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { IKycStatusReq, IKycStatusRes } from 'domains/kyc/interfaces/kyc.interfaces.ts';
import { putKycStatusTokenService } from 'domains/kyc/services/kyc.service.ts';

export const usePutKycStatusMutation = (): UseMutationResult<
  IKycStatusRes,
  Error,
  { reqData: IKycStatusReq }
> =>
  useMutation({
    mutationFn: ({ reqData }: { reqData: IKycStatusReq }) =>
      putKycStatusTokenService(reqData),
  });
