import { ReactElement } from 'react';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { themeNameAtom } from 'common/stores/theme.store.ts';
import { theme } from 'theme/theme.config.ts';
import {
  SContainer,
} from './powered-by.component.styles.ts';

const PoweredBy = (): ReactElement => {
  const { t } = useTranslation();
  const [themeName] = useAtom(themeNameAtom);
  const ApsLogo = theme[themeName].themeV3.apsLogo;

  return (
    <SContainer>
      {t('poweredBy')}
      <ApsLogo />
    </SContainer>
  );
};

export default PoweredBy;
