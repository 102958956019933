import i18n from 'i18next';
import {
  BLOCKCHAIN_NETWORK_MAPPING,
  TRANSACTION_STATUSES_MAPPING,
} from 'domains/transaction/constants/transaction.constants.tsx';
import {
  EBlockchainNetwork,
  ETransactionKind,
  ETransactionStatus,
} from 'domains/transaction/enums/transaction.enums.ts';

export const FILTER_STATUS_MAPPING = [
  {
    label: TRANSACTION_STATUSES_MAPPING[ETransactionStatus.Incomplete].label,
    value: ETransactionStatus.Incomplete,
  },
  {
    label: TRANSACTION_STATUSES_MAPPING[ETransactionStatus.PendingUserTransferStart].label,
    value: [
      ETransactionStatus.PendingUserTransferStart,
      ETransactionStatus.PendingAnchor,
      ETransactionStatus.PendingExternal,
    ].toString(),
  },
  {
    label: TRANSACTION_STATUSES_MAPPING[ETransactionStatus.Error].label,
    value: ETransactionStatus.Error,
  },
  {
    label: TRANSACTION_STATUSES_MAPPING[ETransactionStatus.Completed].label,
    value: ETransactionStatus.Completed,
  },
  {
    label: TRANSACTION_STATUSES_MAPPING[ETransactionStatus.Refunded].label,
    value: ETransactionStatus.Refunded,
  },
  {
    label: TRANSACTION_STATUSES_MAPPING[ETransactionStatus.PendingUser].label,
    value: ETransactionStatus.PendingUser,
  },
];

export const FILTER_TYPE_MAPPING = [
  {
    label: i18n.t('transactions.bottomSheet.filters.type.options.deposit'),
    value: ETransactionKind.Deposit,
  },
  {
    label: i18n.t('transactions.bottomSheet.filters.type.options.withdraw'),
    value: ETransactionKind.Withdrawal,
  },
];

export const FILTER_NETWORK_MAPPING = [
  {
    label: BLOCKCHAIN_NETWORK_MAPPING[EBlockchainNetwork.Stellar].label,
    value: EBlockchainNetwork.Stellar,
  },
];
