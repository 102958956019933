import { createQueryKeys } from '@lukemorales/query-key-factory';
import { EQueryKeys } from 'common/enums/query-keys.enums.ts';
import {
  IUserProfileConfigReqData,
  IUserProfileConfigRes,
  IUserProfileDataRes,
} from 'domains/user/interfaces/user.interfaces.ts';
import {
  getUserProfileConfigService,
  getUserProfileDataService,
  postUserProfileConfigService,
} from 'domains/user/services/user.service.ts';

export const userQueryKeys = createQueryKeys(EQueryKeys.UserKeys, {
  getUserProfileData: () => ({
    queryKey: ['get-user-profile-data'],
    queryFn: (): Promise<IUserProfileDataRes> => getUserProfileDataService(),
  }),
  getUserProfileConfig: () => ({
    queryKey: ['get-user-profile-config'],
    queryFn: (): Promise<IUserProfileConfigRes> => getUserProfileConfigService(),
  }),
  postUserProfileConfig: (data: IUserProfileConfigReqData) => ({
    queryKey: ['post-user-profile-config', data],
    queryFn: (): Promise<IUserProfileConfigRes> => postUserProfileConfigService(data),
  }),
});
