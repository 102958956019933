import { mergeQueryKeys } from '@lukemorales/query-key-factory';
import { authQueryKeys } from 'domains/auth/query-keys/auth.query-keys.ts';
import { kycQueryKeys } from 'domains/kyc/query-keys/kyc.query-keys.ts';
import { onOffRampQueryKeys } from 'domains/on-off-ramp/query-keys/on-off-ramp.query-keys.ts';
import { transactionQueryKeys } from 'domains/transaction/query-keys/transaction.query-keys.ts';
import { userQueryKeys } from 'domains/user/query-keys/user.query-keys.ts';
import { userBillingQueryKeys } from 'domains/user-billing/query-keys/user-billing.query-keys.ts';
import { walletQueryKeys } from 'domains/wallet/query-keys/wallet.query-keys.ts';

export const queryKeysClient = mergeQueryKeys(
  authQueryKeys,
  userBillingQueryKeys,
  kycQueryKeys,
  transactionQueryKeys,
  walletQueryKeys,
  userQueryKeys,
  onOffRampQueryKeys,
);
