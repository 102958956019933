import { Session } from '@ory/kratos-client';
import { FlowError } from '@ory/kratos-client/api.ts';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import type { UseQueryOptions } from '@tanstack/react-query';
import { queryKeysClient } from 'common/clients/query-key.client.ts';
import { EQueryKeys } from 'common/enums/query-keys.enums.ts';

export const useGetToSessionQuery = (
  options?: Omit<UseQueryOptions<Session>, 'queryKey'>,
): UseQueryResult<Session> =>
  useQuery({
    ...options,
    ...(queryKeysClient[EQueryKeys.AuthQueryKeys].getToSession() as unknown as UseQueryOptions<Session>),
  });

export const useGetFlowErrorQuery = (
  errorId: string,
  options?: Omit<UseQueryOptions<FlowError>, 'queryKey'>,
): UseQueryResult<FlowError> =>
  useQuery({
    ...options,
    ...(queryKeysClient[EQueryKeys.AuthQueryKeys].getFlowError(errorId) as unknown as UseQueryOptions<FlowError>),
  });
