import { ReactElement, useEffect, useState } from 'react';
import { Session } from '@ory/kratos-client';
import snsWebSdk from '@sumsub/websdk';
import { useAtom, useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useLoaderData, useSearchParams } from 'react-router-dom';
import { queryKeysClient } from 'common/clients/query-key.client.ts';
import { queryClient } from 'common/clients/query.client.ts';
import { setSmartlookIdentity } from 'common/clients/smartlook.client.ts';
import { Loader } from 'common/components/loader/loader.component.tsx';
import { ESupportLinkType } from 'common/components/support-link/enums/support-link.component.enum.ts';
import SupportLink from 'common/components/support-link/support-link.component.tsx';
import { EQueryKeys } from 'common/enums/query-keys.enums.ts';
import { EUrlQueryParams } from 'common/enums/url-query-params.enums.ts';
import { headerConfigAtom } from 'common/stores/header.store.ts';
import { themeNameAtom } from 'common/stores/theme.store.ts';
import { captureError, IError } from 'common/utils/error.utils.ts';
import { setHttps } from 'common/utils/url.utils.ts';
import { authStartRecursivelyInteractiveService } from 'domains/auth/services/auth.service.ts';
import { usePutKycStatusMutation } from 'domains/kyc/queries/kyc.query.ts';
import { useGetUserProfileDataMutation } from 'domains/user/queries/user.query.ts';
import { IWalletDataRes } from 'domains/wallet/inrefaces/wallet.interfaces.ts';
import { IKycPageLoaderData } from 'pages/kyc/interfaces/kyc.interfaces.ts';
import { SBottomContainer, SWidget } from 'pages/kyc/kyc.page.styles.ts';

const KycPage = (): ReactElement => {
  const { kycTokenData, transactionData } = useLoaderData() as IKycPageLoaderData;
  const { i18n } = useTranslation();
  const [searchParams] = useSearchParams();
  const idQueryParamValue = searchParams.get(EUrlQueryParams.Id) ?? '';
  const { mutateAsync: mutateAsyncPutKycStatusMutation } = usePutKycStatusMutation();
  const { mutateAsync: mutateAsyncGetUserProfileDatMutation } = useGetUserProfileDataMutation();
  const [themeName] = useAtom(themeNameAtom);
  const setHeaderConfig = useSetAtom(headerConfigAtom);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const walletDataFromCache: IWalletDataRes | undefined = queryClient.getQueryData(
    queryKeysClient[EQueryKeys.WalletKeys].getWalletData().queryKey,
  );
  const sessionDataFromCache: Session | undefined = queryClient.getQueryData(
    queryKeysClient[EQueryKeys.AuthQueryKeys].getToSession().queryKey,
  );

  useEffect(() => {
    setHeaderConfig({
      title: 'kyc.title',
      backLink: '',
    });

    return () => {
      setHeaderConfig({
        title: '',
      });
    };
  }, [setHeaderConfig]);

  const getToSession = queryClient.getQueriesData({
    queryKey: queryKeysClient[EQueryKeys.AuthQueryKeys].getToSession._def,
  });
  const userData = getToSession[0][1] as Session;
  const { id: userGuid } = userData.identity ?? { id: '' };

  useEffect(() => {
    const accessToken = kycTokenData.data?.token ?? '';
    const snsWebSdkInstance = snsWebSdk
      .init(accessToken, () => Promise.resolve(accessToken))
      .withConf({
        lang: i18n.language,
        theme: themeName,
      })
      .withOptions({ addViewportTag: false, adaptIframeHeight: true })
      .on('idCheck.onInitialized', () => {
        setIsLoading(false);
      })
      .on('idCheck.onApplicantStatusChanged', async (payloadData) => {
        try {
          const payload = payloadData as {
            reviewStatus?: string;
            reviewResult?: { reviewAnswer?: string };
          };

          if (payload.reviewStatus === 'completed' && payload.reviewResult?.reviewAnswer === 'GREEN') {
            setIsLoading(true);

            const reqData = {
              interactive_id: idQueryParamValue,
              kyc_status: 'success',
            };

            const { return_to: returnTo, is_interactive: isInteractive } = await mutateAsyncPutKycStatusMutation({
              reqData,
            });

            if (returnTo) {
              const profileData = await mutateAsyncGetUserProfileDatMutation({});

              const identityTraits = sessionDataFromCache?.identity?.traits as { email?: string } | undefined;
              const userEmail = identityTraits?.email ?? '';

              setSmartlookIdentity({
                interactiveID: idQueryParamValue,
                userIdentityId: sessionDataFromCache?.identity?.id,
                userEmail,
                kyc_status: profileData.info.kyc_status,
                kyc_level: profileData.info.kyc_level,
                user_city: profileData.info.user_city,
                user_country: profileData.info.country_residence,
                wallet_name: walletDataFromCache?.name,
              });

              if (isInteractive) {
                await authStartRecursivelyInteractiveService(setHttps(`${returnTo}?redirect=false`));
              } else {
                window.location.replace(setHttps(returnTo));
              }
            }
          }
        } catch (error) {
          captureError(error as IError);
          console.error('SumSub error: ', error);
        }
      })
      .on('idCheck.onError', (error) => {
        console.error('SumSub error: ', error);
        setIsLoading(false);
      })
      .build();

    snsWebSdkInstance.launch('#sumsub-websdk-container');
  }, [
    kycTokenData,
    idQueryParamValue,
    i18n.language,
    mutateAsyncPutKycStatusMutation,
    themeName,
    mutateAsyncGetUserProfileDatMutation,
    transactionData,
    walletDataFromCache,
    sessionDataFromCache,
  ]);

  return (
    <>
      {isLoading && <Loader fullscreen />}
      <SWidget id="sumsub-websdk-container" />
      <SBottomContainer>
        <SupportLink
          type={ESupportLinkType.Kyc}
          data={{
            transactionId: transactionData.id,
            userId: userGuid,
            createdAt: transactionData.created_at,
          }}
        />
      </SBottomContainer>
    </>
  );
};

export default KycPage;
