import { LoaderFunctionArgs, redirect } from 'react-router-dom';
import { queryKeysClient } from 'common/clients/query-key.client.ts';
import { queryClient } from 'common/clients/query.client.ts';
import { EQueryKeys } from 'common/enums/query-keys.enums.ts';
import { EUrlQueryParams } from 'common/enums/url-query-params.enums.ts';
import { captureError, IError } from 'common/utils/error.utils.ts';
import { IKycTokenRes } from 'domains/kyc/interfaces/kyc.interfaces.ts';
import { ITransactionDataRes } from 'domains/transaction/interfaces/transaction.interfaces.ts';
import { IKycPageLoaderData } from 'pages/kyc/interfaces/kyc.interfaces.ts';
import { ROUTES_MAPPING } from 'navigation/constants/route.constants.ts';

export const kycPageLoader = async ({ request }: LoaderFunctionArgs): Promise<IKycPageLoaderData | Response> => {
  try {
    const idQueryParamValue = new URL(request.url).searchParams.get(EUrlQueryParams.Id) ?? '';
    const appIdQueryParamValue = new URL(request.url).searchParams.get(EUrlQueryParams.AppId) ?? '';

    let transactionData: ITransactionDataRes | Record<string, never> = {};

    if (idQueryParamValue) {
      const transactionDataQuery = queryKeysClient[EQueryKeys.TransactionKeys].getTransactionData(idQueryParamValue);
      transactionData =
        await queryClient.getQueryData(transactionDataQuery.queryKey)
        ?? await queryClient.fetchQuery(transactionDataQuery);
    }

    const getKycTokenQuery = queryKeysClient[EQueryKeys.KycQueryKeys].getKycToken({
      appId: appIdQueryParamValue,
    });
    const kycTokenData: IKycTokenRes =
      await queryClient.getQueryData(getKycTokenQuery.queryKey)
      ?? await queryClient.fetchQuery(getKycTokenQuery);

    return {
      transactionData,
      kycTokenData,
    };
  } catch (e: unknown) {
    const error = e as {
      response: {
        status: number;
      }
    };

    captureError(error as IError);

    if (error.response.status === 404) {
      return redirect(ROUTES_MAPPING.NO_MATCH);
    }

    return {
      transactionData: {},
      kycTokenData: {},
    };
  }
};
