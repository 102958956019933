import {
  useMutation,
  UseMutationResult,
  useInfiniteQuery,
  type UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseQueryOptions,
  UseQueryResult,
  useQuery,
} from '@tanstack/react-query';
import { queryKeysClient } from 'common/clients/query-key.client.ts';
import { EQueryKeys } from 'common/enums/query-keys.enums.ts';
import {
  ITransactionDataRes,
  ITransactionRefundRes,
  ITransactionsDataRes,
  ITransactionsDataParams,
  ITransactionsCountRes,
} from 'domains/transaction/interfaces/transaction.interfaces.ts';
import {
  countTransactionsService,
  getTransactionDataService,
  postTransactionRefundService,
} from 'domains/transaction/services/transaction.service.ts';

interface IGetTransactionInfiniteQueryData {
  pages: ITransactionsDataRes[],
}

export const useGetTransactionsQuery = (
  params: ITransactionsDataParams,
  options?: Omit<
    UseInfiniteQueryOptions<
      ITransactionsDataRes,
      Error,
      IGetTransactionInfiniteQueryData
    >,
    'queryKey'
  >,
): UseInfiniteQueryResult<IGetTransactionInfiniteQueryData> =>
  useInfiniteQuery<ITransactionsDataRes, Error, IGetTransactionInfiniteQueryData>({
    ...options,
    ...queryKeysClient[EQueryKeys.TransactionKeys].getTransactionsData(params) as unknown as UseInfiniteQueryOptions<
      ITransactionsDataRes,
      Error,
      IGetTransactionInfiniteQueryData
    >,
  });

export const useGetTransactionDataQuery = (
  sep24GUID: string,
  options?: Omit<UseQueryOptions<ITransactionDataRes>, 'queryKey'>,
): UseQueryResult<ITransactionDataRes> =>
  useQuery({
    ...options,
    ...(queryKeysClient[EQueryKeys.TransactionKeys]
      .getTransactionData(sep24GUID) as unknown as UseQueryOptions<ITransactionDataRes>),
  });

export const useGetTransactionDataMutation = (): UseMutationResult<
  ITransactionDataRes,
  Error,
  {
    sep24GUID: string,
  }
> => useMutation({
  mutationFn: ({ sep24GUID }: { sep24GUID: string }) => getTransactionDataService(sep24GUID),
});

export const usePostTransactionRefundMutation = (): UseMutationResult<
  ITransactionRefundRes,
  Error,
  {
    id: string,
  }
> => useMutation({
  mutationFn: ({ id }: { id: string }) => postTransactionRefundService(id),
});

export const useCountTransactionsDataQuery = (
  params: ITransactionsDataParams,
  options?: Omit<UseQueryOptions<ITransactionsCountRes>, 'queryKey'>,
): UseQueryResult<ITransactionsCountRes> =>
  useQuery({
    ...options,
    ...(queryKeysClient[EQueryKeys.TransactionKeys]
      .countTransactionsData(params) as unknown as UseQueryOptions<ITransactionsCountRes>),
  });

export const useCountTransactionsMutation = (): UseMutationResult<
  ITransactionsCountRes,
  Error,
  ITransactionsDataParams
> => useMutation({
  mutationFn: (params: ITransactionsDataParams) => countTransactionsService(params),
});
