import { EApiPathParams } from 'common/enums/api-path-params.enums.ts';
import { ENV_API_POSTFIX, IS_LOCAL } from './env.constants.ts';

// Fix domain name for dyn. env.
let domain = window.location.origin;
const regex = /payments-web3-\d+-\w+-/;

if (regex.test(domain)) {
  domain = domain.replace(regex, '');
}

// const API_URL = IS_LOCAL ? 'https://app1-sep24.armenotech.net' : domain;
const API_URL = IS_LOCAL ? 'https://gollum-sep24.armenotech.net' : domain;

export const API_ENDPOINTS = {
  BASE_URL: `${API_URL}${ENV_API_POSTFIX}`,
  KRATOS_SELF_SERVICE_BASE_URL: `${API_URL}/auth`,
  USER: {
    BILLING_FIELDS: '/user/billing/fields', // TODO: move to USER domain
    PROFILE: '/user/profile',
    CONFIG: '/user/config',
  },
  KYC: {
    TOKEN: '/kyc/token',
    STATUS: '/kyc/status',
  },
  TRANSACTIONS: {
    TRANSACTIONS: '/user/transactions',
    TRANSACTIONS_COUNT: '/user/transactions/count',
    TRANSACTION: `/user/transactions/${EApiPathParams.Sep24GUID}`,
    TRANSACTION_INFO: `/transaction-info/${EApiPathParams.Sep24GUID}`,
    REFUND: `/user/transactions/${EApiPathParams.Sep24GUID}/refund`,
  },
  WALLET: {
    WALLETS: '/user/wallets', // TODO: move to USER domain
    WALLET: '/wallet',
  },
  ON_OFF_RAMP: {
    ORDER_FLOW: '/order-flow',
    ON_RAMP: '/on-ramp',
    OFF_RAMP: '/off-ramp',
  },
};
