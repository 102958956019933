import styled from 'styled-components';
import { Sheet } from 'react-modal-sheet';
import FormItem from 'common/components/form-item/form-item.component.tsx';

export const SSheet = styled(Sheet)`
  .react-modal-sheet-container {
    background: transparent !important;
    border-radius: 30px 30px 0 0 !important;
    
    .react-modal-sheet-content {
      background: ${props => props.theme.themeV3.colors.grey100};
      padding: 0 2px;
      overflow: scroll;
      
      .react-modal-sheet-scroller {
        padding: 0 14px 160px;
      }
    }
  }
`;

export const SSheetHeader = styled(Sheet.Header)`
  border-radius: 8px 8px 0 0;
  background: ${props => props.theme.themeV3.colors.grey100};
  display: flex;
  padding: 16px;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  margin-bottom: -2px;
`;

export const SSheetTitle = styled.div`
  color: ${props => props.theme.themeV3.colors.grey700};
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
`;

export const SCloseIconWrapper = styled.div`
  cursor: pointer;
  
  svg {
    path {
      stroke: ${props => props.theme.themeV3.colors.grey700};
    }
  }
`;

export const SButtonContainer = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 0 16px;
`;

export const SButtonContainerInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 0;
  background: ${props => props.theme.themeV3.colors.grey100};
`;

export const SFormItem = styled(FormItem)`
`;

export const SFilterItem = styled.div`
  padding: 16px 0 0;
  
  .ant-form-item {
    margin-bottom: 0;
  }
`;

export const SFilterItemLabel = styled.div`
  color: ${props => props.theme.themeV3.colors.grey700};
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 24px;
`;
