import { ReactElement } from 'react';
import { Button } from 'antd';

const TestSentryErrorPage = (): ReactElement => (
  <Button
    type="primary"
    size="large"
    danger
    onClick={() => {
      throw new Error('--- TEST ERROR ---');
    }}
  >
    Test Sentry error
  </Button>
);

export default TestSentryErrorPage;
