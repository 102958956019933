import styled from 'styled-components';

export const SContainer = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: ${props => props.theme.themeV3.colors.grey300};
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
`;
