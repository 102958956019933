import moonIcon from 'assets/main-menu/moon.icon.svg?react';
import sunIcon from 'assets/main-menu/sun.icon.svg?react';
import toolIcon from 'assets/main-menu/tool.icon.svg?react';
import { EThemesSelector } from 'theme/enums/theme.enums.ts';

export const THEME_SELECTOR_CONFIG = {
  [EThemesSelector.Auto]: {
    key: EThemesSelector.Auto,
    label: 'mainMenu.screen.theme.selector.auto',
    icon: toolIcon,
  },
  [EThemesSelector.Dark]: {
    key: EThemesSelector.Dark,
    label: 'mainMenu.screen.theme.selector.dark',
    icon: moonIcon,
  },
  [EThemesSelector.Light]: {
    key: EThemesSelector.Light,
    label: 'mainMenu.screen.theme.selector.light',
    icon: sunIcon,
  },
};
