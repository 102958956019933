import { ReactElement } from 'react';
import { StepsProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { SSteps } from './steps.component.styles.ts';

const Steps = (props: StepsProps): ReactElement => {
  const { t } = useTranslation();

  const items = [
    {
      title: t('components.onboardingSteps.1'),
    },
    {
      title: t('components.onboardingSteps.2'),
    },
    {
      title: t('components.onboardingSteps.3'),
    },
  ];

  return <SSteps items={items} responsive={false} {...props} />;
};

export default Steps;
