import { ReactElement } from 'react';
import CalendarIcon from 'assets/calendar.icon.svg?react';
import { SDatePicker } from './date-picker.component.styles.ts';

// @ts-expect-error wrong ANTD types
const DatePicker = (props): ReactElement => (
  <SDatePicker
    suffixIcon={<CalendarIcon />}
    {...props}
  />
);

export default DatePicker;
