import { EWallets } from 'domains/wallet/enums/wallet.enums.ts';
import ApsDarkIcon from 'assets/logo-icons/aps-dark.icon.svg?react';
import ApsLightIcon from 'assets/logo-icons/aps-light.icon.svg?react';
import BeansAppLargeDarkIcon from 'assets/wallet-logo-icons/beans-app-large-dark.icon.svg?react';
import BeansAppLargeLightIcon from 'assets/wallet-logo-icons/beans-app-large-light.icon.svg?react';
import LobstrLargeDarkIcon from 'assets/wallet-logo-icons/lobstr-large-dark.icon.svg?react';
import LobstrLargeLightIcon from 'assets/wallet-logo-icons/lobstr-large-light.icon.svg?react';
import TrustWalletLargeDarkIcon from 'assets/wallet-logo-icons/trust-wallet-large-dark.icon.svg?react';
import TrustWalletLargeLightIcon from 'assets/wallet-logo-icons/trust-wallet-large-light.icon.svg?react';
import { EThemes } from 'theme/enums/theme.enums.ts';

export const theme = {
  [EThemes.Light]: {
    themeV3: {
      apsLogo: ApsLightIcon,
      walletsLogo: {
        [EWallets.Beans]: BeansAppLargeLightIcon,
        [EWallets.Lobstr]: LobstrLargeLightIcon,
        [EWallets.TrustWallet]: TrustWalletLargeLightIcon,
      },
      colors: {
        grey100: '#FFFFFF',
        grey200: '#E8E8E8',
        grey300: '#BDC0C0',
        grey400: '#868888',
        grey500: '#4D5454',
        grey600: '#1C1D1E',
        grey700: '#131313',
        green100: '#E4FEED',
        green200: '#90F1B5',
        green300: '#18CF71',
        green400: '#008D64',
        green500: '#1E3130',
        red100: '#FFD9D9',
        red200: '#FF9797',
        red300: '#E42626',
        red400: '#872C2C',
        red500: '#412424',
        yellow100: '#FFF3CD',
        yellow200: '#FFEBA3',
        yellow300: '#FFC700',
        yellow400: '#C49210',
        yellow500: '#9F6900',
      },
    },
  },
  [EThemes.Dark]: {
    themeV3: {
      apsLogo: ApsDarkIcon,
      walletsLogo: {
        [EWallets.Beans]: BeansAppLargeDarkIcon,
        [EWallets.Lobstr]: LobstrLargeDarkIcon,
        [EWallets.TrustWallet]: TrustWalletLargeDarkIcon,
      },
      colors: {
        grey100: '#131313',
        grey200: '#1C1D1E',
        grey300: '#4D5454',
        grey400: '#868888',
        grey500: '#BDC0C0',
        grey600: '#F5F5F5',
        grey700: '#FFFFFF',
        green100: '#1E3130',
        green200: '#008D64',
        green300: '#18CF71',
        green400: '#90F1B5',
        green500: '#E4FEED',
        red100: '#412424',
        red200: '#872C2C',
        red300: '#E42626',
        red400: '#FF9797',
        red500: '#FFD9D9',
        yellow100: '#9F6900',
        yellow200: '#C49210',
        yellow300: '#FFC700',
        yellow400: '#FFEBA3',
        yellow500: '#FFF3CD',
      },
    },
  },
};
