import { ReactElement } from 'react';
import { useAtomValue } from 'jotai';
import {
  Outlet,
  Navigate,
} from 'react-router-dom';
import { EUrlQueryParams } from 'common/enums/url-query-params.enums.ts';
import { profileConfigAtom } from 'common/stores/profile-config.store.ts';
import { ROUTES_MAPPING } from '../constants/route.constants.ts';

const ProtectedRouteGuard = (): ReactElement => {
  const profileConfig = useAtomValue(profileConfigAtom);
  const isAuthenticatedUser = profileConfig.userIsAuthorized;
  const queryParams = new URLSearchParams(window.location.search);
  queryParams.set(EUrlQueryParams.ReturnTo, window.location.href);

  if (isAuthenticatedUser) {
    return <Outlet />;
  }

  return <Navigate
    to={{
      pathname: ROUTES_MAPPING.PUBLIC.LOGIN,
      search: queryParams.toString(),
    }}
  />;
};

export default ProtectedRouteGuard;
