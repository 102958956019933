import { ReactElement } from 'react';
import { useAtom } from 'jotai';
import { queryKeysClient } from 'common/clients/query-key.client.ts';
import { queryClient } from 'common/clients/query.client.ts';
import { EQueryKeys } from 'common/enums/query-keys.enums.ts';
import { themeNameAtom } from 'common/stores/theme.store.ts';
import { EWallets } from 'domains/wallet/enums/wallet.enums.ts';
import { IWalletDataRes } from 'domains/wallet/inrefaces/wallet.interfaces.ts';
import { theme } from 'theme/theme.config.ts';
import {
  SApsLogoContainer,
  SLogoContainer,
  SSingleApsLogoContainer,
  SWalletLogoContainer,
  SWalletLogoDivider,
} from './wallet-logo.component.styles.ts';

const WalletLogo = (): ReactElement => {
  const [themeName] = useAtom(themeNameAtom);
  const ApsLogo = theme[themeName].themeV3.apsLogo;

  const getWalletDataFromCache = queryClient.getQueriesData({
    queryKey: queryKeysClient[EQueryKeys.WalletKeys].getWalletData._def,
  });

  const walletData = getWalletDataFromCache[0]?.[1] as IWalletDataRes | undefined;

  if (walletData?.key && (walletData.key !== EWallets.Test)) {
    const WalletLogo = theme[themeName].themeV3.walletsLogo[walletData.key];

    return <SLogoContainer>
      <SApsLogoContainer>
        <ApsLogo />
      </SApsLogoContainer>
      <SWalletLogoDivider />
      <SWalletLogoContainer>
        <WalletLogo />
      </SWalletLogoContainer>
    </SLogoContainer>;
  }

  return (
    <SLogoContainer>
      <SSingleApsLogoContainer>
        <ApsLogo />
      </SSingleApsLogoContainer>
    </SLogoContainer>
  );
};

export default WalletLogo;
